import { Grid } from '@material-ui/core'
import { getUserSessions } from 'api/StudentService'
import FullLoader from 'components/FullLoader'
import StudentEmptyScreen from 'pages/StudentEmptyScreen/StudentEmptyScreen'
import React, { useEffect, useState } from 'react'
import Dashboard from './Dashboard'
import Stats from './Stats'

function Home() {
  let [sessions, setSessions] = useState([])
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getUserSessions().then(res => {
      setSessions(res.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }).catch(err => {
      console.log(err)
    })
  }, [])

  if (loading) return <FullLoader />

  return (
    <>
      {sessions.length ? (
        <Grid container>
          <Grid item xs={6}>
            <Dashboard />
          </Grid>
          <Grid item xs={6}>
            <Stats />
          </Grid>
        </Grid>
      ) : <StudentEmptyScreen />}
    </>
  )
}

export default Home
