import { Box, Divider, Grid, useTheme } from '@material-ui/core';
import { db, storageDb } from 'api/firebase';
import { teacher } from 'assets';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import commonStyles from 'Dashboard/Dashboard.module.css';
import AttachmentPreview from 'Dashboard/components/Chat/AttachmentPreview';
import styles from 'Dashboard/components/Chat/chat.module.css';
import IncomingChatMessage from 'Dashboard/components/Chat/IncomingMessage';
import InputContainer from 'Dashboard/components/Chat/InputContainer';
import OutgoingChatMessage from 'Dashboard/components/Chat/OutgoingChatMessage';

function ChatBox({ chatRoomId, userId }) {
  const [messages, setMessages] = useState([]);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [previewAttachment, setPreviewAttachment] = useState(null);
  const [message, setMessage] = useState('');
  const theme = useTheme();
  const bgcolor = theme.palette.primary.light;

  useEffect(() => {
    db.ref(chatRoomId || 'sample').on('value', (snapshot) => {
      let chats = [];
      snapshot.forEach((snap) => {
        chats.push(snap.val());
      });
      setMessages(chats);
    });
  }, []);

  function handleAttachment() {
    setOpenAttachment(false);
    var storageRef = storageDb.child(attachment.name);
    storageRef
      .put(attachment)
      .then((res) => {
        res.ref.getDownloadURL().then((url) => {
          console.log(url);
          sendMessageWithAttachment(url);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let sendMessageWithAttachment = (attach) => {
    setMessage('');
    db.ref(chatRoomId).push({
      attachment: attach,
      userId: userId,
      time: moment().format(),
    });
  };

  let sendMessage = (msg) => {
    setMessage('');
    db.ref(chatRoomId).push({
      message: msg,
      userId: userId,
      time: moment().format(),
    });
  };

  function getMessage(item) {
    if (item?.userId == userId) {
      return <OutgoingChatMessage data={item} />;
    }
    return <IncomingChatMessage data={item} />;
  }

  function handleOpenAttachment() {
    setOpenAttachment(true);
  }

  return (
    <>
      <Box height='100%' display='flex' flexDirection='column'>
        <Box p={1}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item>
              <img src={teacher} width='50px' height='50px' alt='' />
            </Grid>
            <Grid item>
              <h4 className={commonStyles.secondary_title}>vinay</h4>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Divider />
        </Box>
        <Box className={styles.chatmessages_container}>
          <ScrollToBottom
            className={styles.scrolltobottom}
            scrollViewClassName={styles.scrolltobottominner}>
            {messages.map((item) => {
              return getMessage(item);
            })}
          </ScrollToBottom>
          {openAttachment && (
            <AttachmentPreview
              previewAttachment={previewAttachment}
              attachment={attachment}
              handleAttachment={handleAttachment}
              setOpenAttachment={(v) => setOpenAttachment(v)}
            />
          )}
        </Box>
        {!openAttachment && (
          <Box>
            <InputContainer
              videoCallChat
              handleOpenAttachment={handleOpenAttachment}
              setAttachment={(v) => setAttachment(v)}
              setPreviewAttachment={(v) => setPreviewAttachment(v)}
              message={message}
              setMessage={(v) => setMessage(v)}
              sendMessage={(v) => sendMessage(v)}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default ChatBox;
