import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

function VideoLoader() {
	return (
		<Box
			minHeight='100vh'
			display='flex'
			alignItems='center'
			justifyContent='center'>
			<CircularProgress />
		</Box>
	);
}

export default VideoLoader;
