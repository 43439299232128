import React from 'react'

function useFetch(request) {
  const [data, setData] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState()
  React.useEffect(() => {
    setLoading(true)
    request().then((res) => {
      setData(res.data)
      setLoading(false)
    }).catch(err => {
      setError(err)
      setLoading(false)
    })
  }, [])
  return [data, error, loading]
}

export default useFetch
