import { Box, Button, Grid, Typography } from '@material-ui/core'
import { bell } from 'assets'
import commonStyles from 'Dashboard/Dashboard.module.css'
import React from 'react'
import NewBookings from './NewBookings'
import TodayEvents from './TodayEvents'
import CachedIcon from '@material-ui/icons/Cached'
import EmpyData from 'components/EmpyData'

function Dashboard({ bookings, todayEvents, getData }) {
  return (
    <>
      <section className={commonStyles.overflow_section}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h2">My Dashboard</Typography>
          </Grid>
          <Grid item>
            <Button onClick={getData} color='primary' variant='text'>
              <CachedIcon style={{ marginRight: 10 }} />
                 Refresh
              </Button>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant="body1" color="textSecondary">New Bookings</Typography>
          {bookings.length ? bookings.map(item => (
            <NewBookings data={item} getData={getData} />
          )) : <EmpyData label="No new Bookings yet." />
          }
        </Box>
        <TodayEvents todayEvents={todayEvents} />
      </section>
    </>
  )
}

export default Dashboard
