import { Box, Button, Typography } from '@material-ui/core';
import { getAvailableBalance } from 'api/StudentService';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Error from 'utils/Error';
import CoinsShortageDialogue from '../CoinsShortageDialogue';
import SessionPackage from './SessionPackage';
import StudentInfoDialogue from './StudentInfoDialogue';

function SingleSessions({ data }) {
  const match = useRouteMatch()
  let teacherId = match.params.id
  const [selectedSession, setSelectedSession] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCoinsDialogue, setOpenCoinsDialogue] = useState(false)
  const [selectedSessionPrice, setSelectedSessionPrice] = useState<any>(null)
  const [availableBalance, setAvailableBalance] = useState<any>(null)

  const handleClickOpen = async () => {
    if (!selectedSession) {
      Error("Select a session")
      return
    }
    if (!localStorage.getItem("access_token")) {
      Error("Login to book a session")
      return
    }
    let balance = await getAvailableBalance()
    setAvailableBalance(balance.data?.available_balance)
    checkIfBalanceAvailable(balance.data?.available_balance)
  };

  function checkIfBalanceAvailable(availableBalance) {
    if (+availableBalance < +selectedSessionPrice) {
      setOpenCoinsDialogue(true)
      return
    }
    setOpen(true)
  }

  let teacherSessions = [
    { type: 1, value: data?.session_1 },
    { type: 7, value: data?.session_7 },
    { type: 15, value: data?.session_15 },
    { type: 30, value: data?.session_30 }
  ]

  return (
    <>
      <Box mt={3}>
        <Typography variant="body1" color="textSecondary">Select a package</Typography>
        {teacherSessions.map((item, index) => (
          <SessionPackage
            selectedSession={selectedSession}
            setSelectedSession={value => {
              setSelectedSession(value)
              let price = teacherSessions.filter(sess => sess.type == value)[0]?.value
              setSelectedSessionPrice(price)
            }}
            data={item}
            index={index} />
        ))}
        <StudentInfoDialogue
          open={open}
          setOpen={v => setOpen(v)}
          teacherId={teacherId}
          session={selectedSession}
          selectedSessionPrice={selectedSessionPrice}
        />
        {localStorage.getItem('access_token') && (
          <CoinsShortageDialogue
            openCoinsDialogue={openCoinsDialogue}
            setOpenCoinsDialogue={v => setOpenCoinsDialogue(v)}
            availableBalance={availableBalance}
            selectedSessionPrice={selectedSessionPrice}
          />
        )}
        <Box mt={4}>
          <Button variant="contained" onClick={handleClickOpen} color="primary">
            Book Session
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default SingleSessions
