import React from 'react';
import { Box, Radio, Typography, useTheme } from '@material-ui/core';
import { bag } from 'assets';

const SessionPackage = ({ data, index, selectedSession, setSelectedSession }) => {
  const theme = useTheme()
  const bgcolor = theme.palette.secondary.main

  const handleChange = (event) => {
    setSelectedSession(+event.target.value);
  };

  return (
    <Box display="flex" justifyContent="space-between" mt={index === 0 ? 2 : 5}>
      <Box display="flex" gridGap={16} alignItems="center">
        <Box
          style={{ padding: "5px 17px" }}
          textAlign="center"
          borderRadius={10}
          bgcolor={bgcolor} >
          <Typography variant="body1">{data?.type}</Typography>
          <Typography variant="subtitle1">
            {data?.type > 1 ? "Hrs" : "Hr"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {data?.type} {data?.type > 1 ? "Hrs" : "Hr"} Session
          </Typography>
          <Box
            display="flex" gridGap={5} alignItems="center">
            <img width="20px" height="20px" src={bag} alt="" />
            <Typography
              style={{ paddingTop: 3 }}
              variant="subtitle1"
              color="primary">
              {data.value || 0}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Radio
        value={data?.type}
        color="primary"
        about="dfdf"
        name="package"
        checked={selectedSession === data?.type}
        onChange={handleChange}
      />
    </Box>
  )
}

export default SessionPackage
