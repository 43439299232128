import { Box, Grid } from '@material-ui/core'
import React from 'react'
import Details from './Details'
import Earnings from './Earnings'

function MyEarnings() {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Earnings />
        </Grid>
        <Grid item xs={6}>
          <Details />
        </Grid>
      </Grid>
    </>
  )
}

export default MyEarnings
