import { Box, Button, Grid } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import React from 'react'
import ImageUpload from './ImageUpload'
import Error from 'utils/Error'
import { uploadFile } from 'api/StudentService'

function PersonalDetails({ state, nextStep, setState }) {

  function handleSubmit(e) {
    e.preventDefault()
    if (!state.image) {
      Error("Upload an image")
      console.log(state)
      return
    }
    nextStep()
  }

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }


  return (
    <>
      <p className="text_center para_black step">Step 1/4</p>
      <p className="text_center para_black">Personal Details</p>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={3}>
            <Box mt={1}>
              <ImageUpload
                name="image"
                label="Profile Picture"
                onChange={(v) => setState({ image: v })}
              />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <CustomTextField
              rows={7}
              required
              noMargin
              name="overview"
              multiline
              label="About You"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                onChange={handleChange}
                name="qualification"
                required
                type="text"
                label="Qualification"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                onChange={handleChange}
                name="address"
                required
                type="text"
                label="Address"
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                onChange={handleChange}
                name="city"
                required
                type="text"
                label="City"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                onChange={handleChange}
                name="state"
                required
                type="text"
                label="State"
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                onChange={handleChange}
                name="pincode"
                required
                type="text"
                label="Pincode"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                onChange={handleChange}
                required
                name="country"
                type="text"
                label="Country"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            style={{ borderRadius: 10 }}
          >Next</Button>
        </Box>
      </form>
    </>
  )
}

export default PersonalDetails