import AttachFile from "@material-ui/icons/AttachFile";
import { docattachment, imageattachment, pdfattachment, sendmsg } from "assets";
import React from "react";
import styles from "./chat.module.css";

function InputContainer(props) {
	let {
		message,
		setMessage,
		handleOpenAttachment,
		setAttachment,
		setPreviewAttachment,
		sendMessage,
		...remProps
	} = props;

	function handleMessage(e) {
		setMessage(e.target.value);
	}

	function handleAttachment(e) {
		if (e.target.files[0]) {
			handleOpenAttachment();
			setAttachment(e.target.files[0]);
			getAttachmentName(e.target.files[0]);
		}
	}

	function getAttachmentName(file) {
		if (file.type == "application/pdf") {
			console.log("hello");
			setPreviewAttachment(pdfattachment);
		}
		if (
			file.type == "image/png" ||
			file.type == "image/jpg" ||
			file.type == "image/jpeg" ||
			file.type == "image/svg+xml"
		) {
			setPreviewAttachment(imageattachment);
		}
		if (
			file.type ==
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
			file.type ==
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
			file.type == "text/csv"
		) {
			setPreviewAttachment(docattachment);
		}
	}

	function saveMessage(e) {
		if (e.key === "Enter") {
			sendMessage(e.target.value);
		}
	}

	function onSend() {
		sendMessage(message);
	}

	return (
		<>
			<div className={styles.sendmsg}>
				<div className={styles.attachment}>
					<label htmlFor='attachment'>
						<div className={styles.attachment_inner}>
							<AttachFile />
						</div>
					</label>
					<input
						onChange={handleAttachment}
						type='file'
						id='attachment'
						style={{ display: "none" }}
					/>
				</div>
				<input
					value={message}
					onChange={handleMessage}
					placeholder='Type a message'
					onKeyPress={saveMessage}
					type='text'
				/>
				<button onClick={onSend} className='btn'>
					{!remProps.videoCallChat && <span>Send</span>}
					<img src={sendmsg} alt='' />
				</button>
			</div>
		</>
	);
}

export default InputContainer;
