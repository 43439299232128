import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@material-ui/core'
import EmpyData from 'components/EmpyData'
import ProfileMenu from 'components/ProfileMenu'
import commonStyles from 'Dashboard/Dashboard.module.css'
import React, { useState } from 'react'
import GroupSessions from './GroupSessions'
import SingleSessions from './SingleSessions'

function Sessions({ data, groupSessions }) {
  const [sessionType, setSessionType] = useState('one')
  const theme = useTheme()
  const bgcolor = theme.palette.primary.light

  return (
    <>
      <Box className={commonStyles.overflow_section} px={8} py={5} bgcolor={bgcolor}>
        <Box textAlign="right">
          {localStorage.getItem('access_token') && <ProfileMenu />}
        </Box>
        <Box mt={3}>
          <Typography variant="h2">Sessions available</Typography>
          <Box mt={1}>
            <FormControl component="fieldset">
              <RadioGroup
                onChange={e => setSessionType(e.target.value)}
                row
                value={sessionType}
                aria-label="position"
                name="position"
              >
                <FormControlLabel
                  value="one"
                  control={<Radio color="primary" />}
                  label="One on One Sessions"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="group"
                  control={<Radio color="primary" />}
                  label="Group Sessions"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {sessionType == "one" && <SingleSessions data={data} />}
        </Box>
        {sessionType == "group" && <GroupSessions data={groupSessions} />}
      </Box>
    </>
  )
}

export default Sessions


