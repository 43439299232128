import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { teacher } from "assets";
import React from "react";
import commonStyles from "../../Dashboard.module.css";
import CachedIcon from "@material-ui/icons/Cached";
import UpcomingGroupSession from "./UpcomingGroupSession";
import EmpyData from "components/EmpyData";
import ProfileImage from "components/DefaultImage";

let Sessions = ({
  sessions,
  setCurrentSession,
  getData,
  upcomingSessions,
  ...props
}) => {
  return (
    <Box className={commonStyles.overflow_section}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2">Group Sessions</Typography>
        <Button onClick={getData} color="primary" variant="text">
          <CachedIcon style={{ marginRight: 10 }} />
          Refresh
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="body1" color="textSecondary">
          Upcoming group sessions
        </Typography>
        <UpcomingGroupSession data={upcomingSessions} />
      </Box>
      <Box mt={3}>
        <Typography variant="body1" color="textSecondary">
          Completed Sessions
        </Typography>
        {sessions.length ? (
          sessions.map((item, index) => (
            <Session
              key={index}
              onClick={() => {
                setCurrentSession(item);
                props.refresh(item?.id);
              }}
              data={item}
              isLast={index == sessions.length - 1}
            />
          ))
        ) : (
            <EmpyData label="You didn't complete any group sessions yet." />
          )}
      </Box>
    </Box>
  );
};

let Session = ({ data, isLast, onClick }) => {
  return (
    <Box mt={2} onClick={onClick} style={{ cursor: "pointer" }}>
      <Typography variant="h5" color="primary">
        {data?.title} Session
      </Typography>
      <Box mt={2}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <ProfileImage type="text" name={data?.teacherName} />
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  {data?.teacherName}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {data?.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              color="secondary"
              style={{ textTransform: "capitalize" }}
            >
              {data?.status}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {!isLast && (
        <Box mt={2}>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default Sessions;
