import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { logo } from 'assets'
import { useHistory } from 'react-router-dom'
import styles from '../../Welcome.module.css'
import cx from 'classnames'
import ProfileMenu from 'components/ProfileMenu'

function Header() {
  const history = useHistory()
  let isLoggedIn = localStorage.getItem('access_token') ? true : false
  return (
    <>
      <Box py={2} className={styles.header} >
        <Grid container justify="center">
          <Grid item xs={10}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <img src={logo} alt="" />
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={3}>
                  {isLoggedIn ? <ProfileMenu /> : (
                    <>
                      <Grid item>
                        <button onClick={() => history.push('/teacher-login')} className={cx(styles.btn, styles.teacher_login_btn)}>Techer Login</button>
                      </Grid>
                      <Grid item>
                        <button onClick={() => history.push('/login')} className={cx(styles.btn, styles.get_started_btn)} >Get Started</button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Header

