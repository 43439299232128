import { PureComponent } from "react";
import TwilioVideo from "twilio-video";
import { isEmpty, first } from "lodash";
import { getToken } from "api/StudentService";
import axios from "axios";

class AppContainer extends PureComponent {
	state = {
		videoRoom: null,
		isJoining: true,
		errorMessage: null,
		audio: true,
		video: true,
		localParticipant: false,
		type: "teacher",
		isRecord: false,
	};

	getToken = async (user) => {
		const response = await getToken(user);
		console.log(response.data.token);
		return response.data.token;
	};

	joinRoom = async (room, user, setLoading) => {
		const { roomName } = this.state;
		this.setState({ isJoining: true });
		setLoading(true);
		try {
			const token = await this.getToken(user);
			const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
			this.setState({ localVideoTrack });
			const localAudioTrack = await TwilioVideo.createLocalAudioTrack();
			this.setState({ localAudioTrack });
			const videoRoom = await TwilioVideo.connect(token, {
				name: room,
				tracks: [localVideoTrack, localAudioTrack],
				insights: false,
				RecordParticipantsOnConnect: this.state.isRecord,
				dominantSpeaker: this.state.type === "teacher" ? true : false,
			});
			setLoading(false);
			videoRoom.on("disconnected", () => {
				this.stopVideoTrack();
				this.stopAudioTrack();
				this.stopScreenTrack();

				this.setState({
					videoRoom: null,
				});
			});

			videoRoom.on("dominantSpeakerChanged", (participant) => {
				console.log("The new dominant speaker in the Room is:", participant);
			});

			this.setState({ videoRoom });
		} catch (error) {
			this.stopVideoTrack();
			this.stopAudioTrack();
			this.setState({
				errorMessage: error.message,
			});
		}
		this.setState({ isJoining: false });
	};

	leaveRoom = async () => {
		const { videoRoom } = this.state;
		if (videoRoom) {
			videoRoom.disconnect();
		}
	};

	stopTrack = (trackName) => {
		const track = this.state[trackName];
		if (track) {
			track.stop();
			this.setState({ [trackName]: null });
		}
	};

	stopScreenTrack = () => this.stopTrack("screenTrack");
	stopVideoTrack = () => this.stopTrack("localVideoTrack");
	stopAudioTrack = () => this.stopTrack("localAudioTrack");

	shareScreen = async () => {
		try {
			const {
				videoRoom,
				localVideoTrack,
				screenTrack,
				localScreenShareTrack,
			} = this.state;
			if (!screenTrack) {
				const stream = await navigator.mediaDevices.getDisplayMedia({
					video: true,
				});
				const newScreenTrack = first(stream.getVideoTracks());
				this.setState({
					screenTrack: new TwilioVideo.LocalVideoTrack(newScreenTrack),
					localScreenShareTrack: !localScreenShareTrack,
				});
				videoRoom.localParticipant.publishTrack(newScreenTrack);
				videoRoom.localParticipant.unpublishTrack(localVideoTrack);

				stream.oninactive = () => {
					videoRoom.localParticipant.unpublishTrack(newScreenTrack);
					videoRoom.localParticipant.publishTrack(localVideoTrack);
					this.stopScreenTrack();
				};
			} else {
				videoRoom.localParticipant.unpublishTrack(screenTrack);
				videoRoom.localParticipant.publishTrack(localVideoTrack);
				this.stopScreenTrack();
			}
		} catch (error) {
			this.stopScreenTrack();
			this.setState({
				errorMessage: error.message,
			});
		}
	};

	hideErrorMessage = () => this.setState({ errorMessage: null });
	changeUserName = (userName) => this.setState({ userName });
	changeRoomName = (roomName) => this.setState({ roomName });
	muteAudio = () => this.setState();

	render() {
		const { render } = this.props;
		const {
			videoRoom,
			isJoining,
			userName,
			roomName,
			errorMessage,
			screenTrack,
			localScreenShareTrack,
			isRecord,
		} = this.state;

		return render({
			videoRoom,
			userName,
			roomName,
			isVideoSupported: TwilioVideo.isSupported,
			isScreenSharingSupported: Boolean(
				navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
			),
			isScreenSharingEnabled: Boolean(screenTrack),
			canJoin: !isEmpty(userName) && !isEmpty(roomName),
			isJoining,
			onJoin: this.joinRoom,
			onLeave: this.leaveRoom,
			onShare: this.shareScreen,
			onRoomNameChange: this.changeRoomName,
			onUserNameChange: this.changeUserName,
			errorMessage,
			onErrorMessageHide: this.hideErrorMessage,
		});
	}
}

export default AppContainer;
