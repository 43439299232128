import { Box, CircularProgress } from '@material-ui/core';
import { getTeacherProfile } from 'api/StudentService';
import { logo } from 'assets';
import Menu from 'Dashboard/components/Menu';
import Profile from 'Dashboard/components/Profile';
import styles from 'Dashboard/Dashboard.module.css';
import useFetch from 'hooks/useFetch';
import React, { Context, createContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import menu from '../components/TeacherMenu';
import Routes from './Routes';

export let TeacherContext: Context<{}> = createContext({})

function DashboardLayout() {
  let [data, loading, error] = useFetch(getTeacherProfile)
  if (loading) return <CircularProgress />

  return (
    <>
      <TeacherContext.Provider value={data}>
        <div className={styles.root} >
          <nav className={styles.sidenav}>
            <div className={styles.logo}>
              <Link to="/welcome">
                <img src={logo} alt="" />
              </Link>
            </div>
            <Box mt={5} className={styles.profile}>
              <Profile type="teacher" />
            </Box>
            <Box mt={4}>
              <Menu menu={menu} />
            </Box>
          </nav>
          <main className={styles.main_content}>
            <Routes />
          </main>
        </div>
      </TeacherContext.Provider>
    </>
  )
}

export default withRouter(DashboardLayout) 
