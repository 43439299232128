import { getUserChats } from 'api/StudentService';
import FullLoader from 'components/FullLoader';
import Chat from 'Dashboard/components/Chat';
import React, { useEffect, useState } from 'react';

function StudentChat() {
  let [sessions, setSessions] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserChats()
      .then((res) => {
        setSessions(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  if (loading) return <FullLoader />;

  return (
    <>
      <Chat sessions={sessions || []} type='student' />
    </>
  );
}

export default StudentChat;
