import { logo } from 'assets'
import React, { useState } from 'react'
import Login from './components/Login'
import Registration from './components/Register'
import './Login.css'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

function StudentLogin() {

  const [step, setStep] = useState(1)

  function renderLoginSteps() {
    switch (step) {
      case 1:
        return <Login nextStep={nextStep} />
      case 2:
        return <Registration nextStep={nextStep} />
      default:
        return null
    }
  }

  function nextStep() {
    setStep((step) => step + 1)
  }

  return (
    <>
      <main className="login">
        <header>
          <Link to="/welcome">
            <img src={logo} className="img_fluid" alt="" />
          </Link>
          <Link to="/teacher-login" style={{ textDecoration: "none" }}>
            <Button color="secondary">Teacher Login</Button>
          </Link>
        </header>
        <div className={`login_form ${step == 2 && "register_form"}`} >
          {renderLoginSteps()}
        </div>
      </main>
    </>
  )
}

export default StudentLogin