import { Box, Typography, useTheme } from '@material-ui/core'
import { bag } from 'assets'
import commonStyles from 'Dashboard/Dashboard.module.css'
import React from 'react'

function PricingDetails({ data }) {
  const theme = useTheme()
  const bgcolor = theme.palette.primary.light
  const secondaryBgcolor = theme.palette.secondary.main

  let teacherSessions = [
    { type: 1, value: data?.session_1 },
    { type: 7, value: data?.session_7 },
    { type: 15, value: data?.session_15 },
    { type: 30, value: data?.session_30 }
  ]

  return (
    <>
      <Box className={commonStyles.overflow_section} bgcolor={bgcolor}>
        <Typography variant="h2">Pricing Details</Typography>
        <Box mt={3}>
          {teacherSessions.map((item, index) => (
            <PricingPackage
              data={item}
              index={index}
              bgcolor={secondaryBgcolor}
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

function PricingPackage({ data, index, bgcolor }) {
  return (
    <Box display="flex" justifyContent="space-between" mt={index === 0 ? 2 : 5}>
      <Box display="flex" gridGap={16} alignItems="center">
        <Box
          style={{ padding: "5px 17px" }}
          textAlign="center"
          borderRadius={10}
          bgcolor={bgcolor} >
          <Typography variant="body1">{data?.type}</Typography>
          <Typography variant="subtitle1">
            {data?.type > 1 ? "Hrs" : "Hr"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {data?.type} {data?.type > 1 ? "Hrs" : "Hr"} Session
          </Typography>
          <Box
            display="flex" gridGap={5} alignItems="center">
            <img width="20px" height="20px" src={bag} alt="" />
            <Typography
              style={{ paddingTop: 3 }}
              variant="subtitle1"
              color="primary">
              {data.value || 0}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}

export default PricingDetails

