import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { howitworks1, howitworks3, howitworks2, arrow1, arrow2, arrow3 } from 'assets'
import styles from '../../Welcome.module.css'
import cx from 'classnames'

function HowItWorks() {
  return (
    <>
      <div className={styles.how_it_works}>
        <Grid container justify="center" >
          <Grid item xs={10}>
            <Grid container justify="center" style={{ position: 'relative' }}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <HowItWorksCard img={howitworks1} desc="1. Pay and book a Package suitable for you by selecting the subject and teacher" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box py={10}>
                  <h1 className={cx(styles.title_primary, styles.text_center)}>How it works</h1>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="space-between">
                  <Grid item xs={6}>
                    <HowItWorksCard img={howitworks2} desc="2. Chat with teacher using Chat feature and schedule the session at a particular time" />
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <HowItWorksCard img={howitworks3} desc="3. Teacher will initiate the call at said time and Sessions can be recorded for future use" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/**** ARROWS ******/}
              <img src={arrow1} className={cx(styles.arrow1, "img_fluid")} alt="" />
              <img src={arrow2} className={cx(styles.arrow2, "img_fluid")} alt="" />
              <img src={arrow3} className={cx(styles.arrow3, "img_fluid")} alt="" />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}


function HowItWorksCard({ img, desc }) {
  return (
    <div style={{ maxWidth: 450 }} className={cx(styles.how_it_works_card, styles.text_center)}>
      <img src={img} className="img_fluid" alt="" />
      <p className={styles.black_medium_title}>{desc}</p>
    </div>
  )
}

export default HowItWorks
