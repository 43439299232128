import React from 'react'
import { Box, TextField } from '@material-ui/core'

function CustomTextField({ label = "", type = "text", ...props }) {
  return (
    <Box mt={props.noMargin ? 0 : 2}>
      <TextField
        InputLabelProps={{ shrink: true }}
        margin="dense"
        fullWidth
        {...props}
        variant="outlined"
        label={label}
        type={type} />
    </Box>
  )
}

export default CustomTextField