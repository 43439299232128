import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { feature1, feature2, feature3, feature4 } from 'assets'
import styles from '../../Welcome.module.css'
import cx from 'classnames'

let features = [
  {
    title: 'Attend session on web or mobile',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
    img: feature1
  },
  {
    title: 'Book your session(s)',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
    img: feature2
  },
  {
    title: 'Record your sessions',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
    img: feature3
  },
  {
    title: 'Seamless Communication',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
    img: feature4
  }
]

function StudentFeatures() {
  return (
    <>
      <section className={styles.student_features}>
        <Grid container justify="center">
          <Grid item xs={8}>
            <Grid container justify="space-between">
              {
                features.slice(0, 2).map((item, index) => (
                  <FeatureCard img={item.img} title={item.title} desc={item.desc} />
                ))
              }
            </Grid>
            <Box my={10} textAlign="center">
              <h1 className={styles.title_primary}>What a student can do</h1>
              <button className={cx(styles.btn, styles.get_started_btn)}>Get Started</button>
            </Box>
            <Grid container justify="space-between">
              {
                features.slice(2).map((item, index) => (
                  <FeatureCard img={item.img} title={item.title} desc={item.desc} />
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </section>
    </>
  )
}


function FeatureCard({ img, title, desc }) {
  return (
    <>
      <Grid item xs={4}>
        <div className={styles.text_center}>
          <img className="img_fluid" src={img} alt="" />
          <h6 className={cx(styles.black_medium_title, styles.pt1)}>{title}</h6>
          <p className={cx(styles.para_black, styles.o7)}>{desc}</p>
        </div>
      </Grid>
    </>
  )
}


export default StudentFeatures
