import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { teacher } from "assets";
import ProfileImage from "components/DefaultImage";
import React from "react";
import commonStyles from "../../Dashboard.module.css";

let Sessions = ({ sessions, setCurrentSession, refresh, ...props }) => {
  return (
    <Box className={commonStyles.overflow_section}>
      <Typography variant="h2">My Sessions</Typography>
      <Box mt={3}>
        {sessions.map((item, index) => (
          <Session
            key={index}
            onClick={() => {
              setCurrentSession(item);
              refresh(item.id);
            }}
            data={item}
            isLast={index == sessions.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
};

let Session = ({ data, isLast, onClick }) => {
  return (
    <Box mt={2} onClick={onClick} style={{ cursor: "pointer" }}>
      <Typography variant="h5" color="primary">
        {data?.subjectName} Session
      </Typography>
      <Box mt={2}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <ProfileImage type="text" name={data?.teacherName} />
              </Grid>
              <Grid item>
                <Typography variant="h5" color="primary">
                  {data?.teacherName}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {data?.completedClasses ?? "00"}/{data?.totalClasses ?? "00"}{" "}
                  classes completed
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              color="secondary"
              style={{ textTransform: "capitalize" }}
            >
              {data?.status}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {!isLast && (
        <Box mt={2}>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default Sessions;
