import { Box, Button, Grid, Typography, useTheme } from "@material-ui/core";
import { updateEvent } from "api/StudentService";
import cx from "classnames";
import ProfileImage from "components/DefaultImage";
import CompleteEventDialogue from "Dashboard/components/SessionDetails/CompleteConfirmDialogue";
import commonStyles from "Dashboard/Dashboard.module.css";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

function GroupSessionsDetails({ data }) {
  let theme = useTheme();
  let bgcolor = theme.palette.primary.light;
  const history = useHistory();
  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false)

  function handleEvent() {
    updateEvent(data?.events[0]?.id, { status: "on-call" })
      .then((res) => {
        history.push(
          `/video-call?channel=${data?.events[0]?.channel}&username=teacher&eventId=${data?.events[0]?.id}&chatRoomId=${data?.chatRoomId}`
        );
      }).catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Box
        p={4}
        bgcolor={bgcolor}
        className={cx(commonStyles.overflow_section)}>
        <Typography variant="h2" color="initial">
          Group Session Details
        </Typography>
        <Box mt={1}>
          <Typography variant="body1" color="textSecondary">
            All Sessions
          </Typography>
          <Box display="flex" gridGap={2}>
            <Typography variant="body1">Slots filled :</Typography>
            <Typography variant="body1" color="primary">
              {data?.users?.length}/{data?.noOfSlots || 0}
            </Typography>
          </Box>
        </Box>
        <Box>
          {data?.users?.length ? (
            data?.users?.map((item) => <SessionMember data={item} />)
          ) : (
              <Box mt={2}>
                <Typography variant="body1" color="textSecondary">
                  No one booked this slot yet.
              </Typography>
              </Box>
            )}
        </Box>
        {data?.users?.length ? (
          <Box mt={3} display="flex" gridGap={10} flexWrap="wrap">
            {moment(data?.events[0]?.time).isBefore(moment().format()) ? (
              <Button
                size="small"
                onClick={handleEvent}
                variant="contained"
                color="primary">
                Start Session
              </Button>
            ) : (
                <Button
                  size="small"
                  disabled
                  variant="contained"
                  color="primary">
                  Start Session
                </Button>
              )}
            <Button
              onClick={() => setOpenConfirmDialogue(true)}
              size="small"
              variant="contained"
              color="primary">
              Complete Session
            </Button>
          </Box>
        ) : null}
      </Box>
      <CompleteEventDialogue
        open={openConfirmDialogue}
        setOpen={setOpenConfirmDialogue}
        text="session"
        handleSubmit={() => { }} />
    </>
  );
}

const SessionMember = ({ data }) => (
  <Box mt={2}>
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <ProfileImage
          type='text'
          name={data?.fullName}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="primary">
          {data?.fullName}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default GroupSessionsDetails;
