import React from 'react'
import About from './About'
import StudentFeatures from './StudentFeatures'
import VerifiedTechers from './VerifiedTechers'
import HowItWorks from './HowItWorks'
import Download from './Download'
import BecomeTeacher from './BecomeTeacher'
import styles from '../../Welcome.module.css'
import cx from 'classnames'

function Main() {
  return (
    <section className={cx(styles.secondary_bg, styles.block)}>
      <About />
      <section className={cx(styles.white_bg, styles.block)}>
        <StudentFeatures />
        <section className={cx(styles.secondary_bg, styles.block)}>
          <VerifiedTechers />
          <section className={cx(styles.white_bg, styles.block)}>
            <HowItWorks />
            <section className={cx(styles.secondary_bg, styles.block)}>
              <Download />
              <section className={cx(styles.main_bg, styles.block)}>
                <BecomeTeacher />
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  )
}

export default Main
