import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createEvent } from 'api/StudentService';
import CustomTextField from 'components/CustomTextField';
import React, { useState } from 'react';

function ScheduleSessionDialogue({ open, setOpen, id = "", ...props }) {
  const [state, setState] = useState({
    time: "",
    topics: ""
  })

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(state)
    createEvent({ ...state, sessionRequestId: id }).then(res => {
      setOpen(false)
      if (props.refresh) {
        props.refresh(id)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box p={5}>
          <Typography
            style={{ textAlign: "center" }}
            variant="subtitle2">
            Schedule Session
          </Typography>
          <form action="" onSubmit={handleSubmit}>
            <CustomTextField
              name="time"
              onChange={handleChange}
              required
              type="datetime-local"
              label="Date and Time"
            />
            <CustomTextField
              name="topics"
              onChange={handleChange}
              type="text"
              multiline
              rows={5}
              label="Topics"
              required
            />
            <Box mt={3}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Schedule
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  )
}

export default ScheduleSessionDialogue
