import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createEvent, createGroupSession } from 'api/StudentService';
import CustomTextField from 'components/CustomTextField';
import React, { useState } from 'react';

function CreateGroupSessionDialogue({ open, setOpen, id = "", ...props }) {
  const [state, setState] = useState({
    time: "",
    topics: "",
    noOfSlots: "",
    title: ""
  })

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(state)
    createGroupSession(state).then(res => {
      console.log(res.data)
      setOpen(false)
      if (props.refresh) {
        props.refresh()
      }
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box p={5}>
          <Typography
            variant="subtitle1"
            style={{ textAlign: "center" }}>
            Create Group Session
          </Typography>
          <form action="" onSubmit={handleSubmit}>
            <CustomTextField
              name="noOfSlots"
              onChange={handleChange}
              required
              type="number"
              label="No of slots"
            />
            <CustomTextField
              name="title"
              onChange={handleChange}
              type="text"
              label="Session Title"
              required
            />
            <CustomTextField
              name="time"
              onChange={handleChange}
              type="datetime-local"
              label="Date and Time"
              required
            />
            <CustomTextField
              name="topics"
              onChange={handleChange}
              multiline
              rows={5}
              label="Topics"
              required
            />
            <Box mt={3}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  )
}

export default CreateGroupSessionDialogue
