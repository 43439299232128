import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Profile from 'pages/TeacherProfile/components/Profile'
import FullLoader from 'components/FullLoader'
import { getTeacher } from 'api/StudentService'
import { useHistory } from 'react-router-dom'
import PricingDetails from './PricingDetails'

function TeacherProfile() {
  const history = useHistory()
  let teacherId = history.location.state.teacherId
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getTeacher(teacherId).then(res => {
      setData(res.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }).catch(err => {
      console.log(err)
    })
  }, [])

  if (loading) return <FullLoader />

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Profile type="in_dashboard" data={data} />
        </Grid>
        <Grid item xs={6}>
          <PricingDetails data={data} />
        </Grid>
      </Grid>
    </>
  )
}

export default TeacherProfile
