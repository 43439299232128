import { Box, Button, CircularProgress } from '@material-ui/core'
import { teacherLogin } from 'api/StudentService'
import CustomTextField from 'components/CustomTextField'
import React, { useState } from 'react'
import Error from 'utils/Error'

function Login({ nextStep, setState }) {
  const [btnLoader, setBtnLoader] = useState(false)
  let [data, setData] = useState({
    username: '',
    password: ''
  })
  function handleChange(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    setBtnLoader(true)
    teacherLogin(data).then(res => {
      setBtnLoader(false)
      if (res.data?.isFullyRegistered) {
        localStorage.setItem('access_token', `Bearer ${res.data.access_token}`)
        localStorage.setItem('user', "teacher")
        localStorage.setItem('is_fully_registered', res.data.isFullyRegistered)
        localStorage.setItem("userId", res?.data?.userId)
        window.location.href = "/"
        return
      }
      setState({ teacherId: res?.data?.teacherId })
      nextStep()
    }).catch(err => {
      console.log(err)
      setBtnLoader(false)
      Error("You are not authorized")
    })
  }

  return (
    <>
      <p className="text_center para_black">Login to your Teacher account</p>
      <form onSubmit={handleSubmit}>
        <Box mt={2}>
          <CustomTextField
            onChange={handleChange}
            name="username"
            required
            type="email"
            label="Email"
          />
        </Box>
        <Box mt={2}>
          <CustomTextField
            onChange={handleChange}
            name="password"
            required
            type="password"
            label="Password"
          />
        </Box>
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            style={{ borderRadius: 10 }}>
            {btnLoader ? <CircularProgress color="secondary" /> : "Proceed"}
          </Button>
        </Box>
      </form>
    </>
  )
}

export default Login