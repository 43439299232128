import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { teacher } from 'assets'
import ProfileImage from 'components/DefaultImage'
import React from 'react'
import styles from './chat.module.css'

function ChatContainer(props) {
  return (
    <>
      <Box mt={2}>
        <Typography variant="body1" color="textSecondary">Chat</Typography>
        <Box mt={2}>
          {props?.sessions.map((item, index) => (
            <ChatPerson
              type={props.type}
              data={item}
              setCurrentChat={v => props.setCurrentChat(v)}
              noBorder={props?.sessions.length - 1 === index} />
          ))}
        </Box>
      </Box>
    </>
  )
}

function ChatPerson({ data, noBorder, type, setCurrentChat }) {
  return (
    <Box mt={3}
      style={{ cursor: 'pointer' }}
      className={styles.person}
      onClick={() => setCurrentChat(data)}>
      <Grid container justify="space-between">
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <ProfileImage
                type="text"
                name={type == "student" ? data?.teacherName : data?.fullName} />
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {type == "student" ? data?.teacherName : data?.fullName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      {!noBorder && <Box mt={3}><Divider /></Box>}
    </Box >
  )
}

export default ChatContainer
