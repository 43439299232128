import React, { useEffect, useState } from "react";
import commonStyles from "../../Dashboard.module.css";
import { Box, Grid, Divider, Typography, useTheme, Button } from "@material-ui/core";
import { teacher } from "assets";
import Rating from "@material-ui/lab/Rating";
import { getUserDashBoard } from "api/StudentService";
import FullLoader from "components/FullLoader";

function Stats() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const bgcolor = theme.palette.primary.light;

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    getUserDashBoard()
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <FullLoader />;

  return (
    <>
      <Box bgcolor={bgcolor} className={commonStyles.overflow_section}>
        <Typography variant="h2">Stats</Typography>
        <TotalSessionsAttended data={data} />
      </Box>
    </>
  );
}

function TotalSessionsAttended({ data }) {
  return (
    <Box mt={4}>
      <Typography variant="body1" color="textSecondary">
        Total sessions attended
      </Typography>
      <Box mt={2}>
        <Grid container spacing={4}>
          <StatCard number={data?.one_on_one} content="One on one sessions" />
          <StatCard number={data?.group_sessions} content="Group Sessions" />
          <StatCard number={data?.topics_covered} content="Topics" />
          <StatCard number={data?.active_sessions} content="Active Sessions" />
        </Grid>
      </Box>
    </Box>
  );
}

const StatCard = ({ number, content }) => (
  <Grid item xs={6}>
    <Box bgcolor="white" borderRadius={16} px={2} py={3} textAlign="center">
      <Typography variant="h2" color="primary">
        {number}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {content}
      </Typography>
    </Box>
  </Grid>
);

function Detailed() {
  let detailed = [1, 2, 3, 4];
  return (
    <Box mt={4}>
      <Typography variant="body1" color="textSecondary">Detailed</Typography>
      <Box bgcolor="white" p={2} borderRadius={16} mt={2}>
        {detailed.map((item, index) => (
          <SessionDetail
            firstSession={index === 0}
            noBorder={detailed.length - 1 === index}
          />
        ))}
      </Box>
    </Box>
  );
}

function SessionDetail({ firstSession, noBorder }) {
  return (
    <>
      <Box mt={firstSession ? 0 : 4}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h5" color="primary">Mathematics</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="textSecondary">12 sessions</Typography>
          </Grid>
        </Grid>
        {!noBorder && (
          <Box mt={3}>
            <Divider />
          </Box>
        )}
      </Box>
    </>
  );
}

function CompletedSessions() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme()
  const bgcolor = theme.palette.primary.main

  return (
    <Box mt={4} >
      <Typography variant="body1" color="textSecondary">Completed sessions</Typography>
      <Box mt={2}>
        <Box bgcolor={bgcolor} borderRadius={16} p={2}>
          <Box mt={2}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <img src={teacher} width="50px" height="50px" alt="" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" style={{ color: "white" }}>Shashank Preetham</Typography>
                    <Typography variant="body1" style={{ color: "white", opacity: 0.6 }}>Rebecca Sullivan</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary">Re-Book</Button>
              </Grid>
            </Grid>
          </Box>
          <Box my={3}>
            <Divider style={{ background: "rgba(255,255,255,0.2)" }} />
          </Box>
          <Box mt={2} className="rating">
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="body1" style={{ color: "white" }}>Rate this session</Typography>
              </Grid>
              <Grid item>
                <Box component="fieldset" mb={3} borderColor="transparent">
                  <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => setValue(newValue || 0)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

export default Stats;
