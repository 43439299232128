import { Avatar, Box, Grid, Typography, useTheme } from '@material-ui/core';
import { UserContext } from 'Dashboard/StudentDashboard';
import { TeacherContext } from 'Dashboard/TeacherDashboard';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

function Profile(props) {
  const teacher: any = useContext(TeacherContext)
  const student: any = useContext(UserContext)
  const theme = useTheme()
  const bgcolor = theme.palette.primary.light

  const avatarStyles: any = {
    textTransform: "uppercase",
    background: bgcolor,
    color: "initial",
    width: 50,
    height: 50
  }
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={3}>
        <Avatar style={avatarStyles}>
          {props.type == "teacher" ?
            teacher?.user?.fullName[0] :
            student?.fullName[0]}
        </Avatar>
      </Grid>
      <Grid item xs={9}>
        <Box>
          <Typography variant="subtitle2" style={{ textTransform: "capitalize" }}>
            {props.type == "teacher" ?
              teacher?.user?.fullName?.split(" ")[0] :
              student?.fullName?.split(" ")[0]}
          </Typography>
          {props.type == "teacher" && (
            <Link
              to={{
                pathname: "/profile",
                state: { teacherId: teacher?.id }
              }}
              style={{ textDecoration: "none" }}>
              <Typography variant="body1" color="primary">
                View Profile
              </Typography>
            </Link>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Profile