import React from 'react'
import { bag } from 'assets'
import { Box, Radio, Typography } from '@material-ui/core'

let CoinPackage = ({ index, value, selectedValue, handleChange }) => (
  <Box display="flex" justifyContent="space-between" mt={index === 0 ? 2 : 5} gridGap={10}>
    <Box
      padding={2}
      bgcolor="white"
      borderRadius={10}
      display="flex"
      gridGap={5}
      alignItems="center"
      flex={1}
    >
      <img src={bag} width="20px" height="20px" alt="" />
      <Typography variant="h5" color="primary">  {value} Coins</Typography>
    </Box>
    <Radio
      value={value}
      color="primary"
      name="package"
      checked={selectedValue == value}
      onChange={handleChange}
    />
  </Box>
)

export default CoinPackage