import React from 'react'
import { Grid, Box, CircularProgress } from '@material-ui/core'
import { bannerimg } from 'assets'
import styles from '../../Welcome.module.css'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { getSubjects } from 'api/StudentService'




function Content() {
  const [subjects, setSubjects] = React.useState<any>([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    getSubjects().then((res) => {
      setSubjects(res.data)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }, [])

  if (loading) return <CircularProgress />

  return (
    <>
      <Box mt={10}>
        <Grid container justify="center">
          <Grid item xs={8}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={7}>
                <Box pl={4}>
                  <h1 className={styles.title_white}>A New Era of learning</h1>
                  <p className={cx(styles.para_white, styles.o7, styles.pt1)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                  <Box mt={3}>
                    <Link to="/book-session">
                      <button className={cx(styles.btn, styles.book_session_btn)}>Book Session(s)</button>
                    </Link>
                  </Box>
                  <Box mt={6} className={styles.subjects}>
                    <h3>Top Subjects to choose from</h3>
                    <div className={styles.subjects_container}>
                      {subjects.length && subjects.map((subject) => <div>{subject.name}</div>)}
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <div className={styles.banner_img}>
                  <img src={bannerimg} className="img_fluid" alt="" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}



export default Content
