import {
  navicon1,
  navicon2,
  navicon3,
  navicon4,
  navicon5,
  navicon6,
  navicon7,
} from 'assets';

const routes = [
  {
    name: 'My Dashboard',
    path: '/',
    icon: navicon1,
  },
  {
    name: 'Ishva Coins',
    path: '/ishva-coins',
    icon: navicon2,
  },
  {
    name: 'Upcoming Events',
    path: '/upcoming-events',
    icon: navicon5,
  },
  {
    name: 'My Sessions',
    path: '/my-sessions',
    icon: navicon6,
  },
  {
    name: 'Group Sessions',
    path: '/group-sessions',
    icon: navicon7,
  },
  {
    name: 'Completed Sessions',
    path: '/completed-sessions',
    icon: navicon3,
  },
  {
    name: 'Chat',
    path: '/chat',
    icon: navicon4,
  },
];

export default routes;
