import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CallEndIcon from '@material-ui/icons/CallEnd';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import { uploadRecording } from 'api/StudentService';
import { logo } from 'assets';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import EndCallDialogue from '../EndCallDialogue';
import { toggleFullScreen } from '../utils';

let recorder, stream;

function ControlBar(props) {
  const {
    mic,
    handleMic,
    eventId,
    handleVideo,
    isScreenSharingEnabled,
    isScreenSharingSupported,
    onLeave,
    onShare,
    videocam,
  } = props;

  const [isRecord, setIsRecord] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const userId = localStorage.getItem('userId');

  async function StartRecording() {
    try {
      stream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: 'screen' },
      });
      recorder = new MediaRecorder(stream);
      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      console.log(recorder);
      recorder.onstop = (e) => {
        setIsRecord(false);
        const completeBlob = new Blob(chunks, { type: chunks[0].type });
        let formData = new FormData();
        formData.append('file', completeBlob);
        formData.append('userId', userId);
        uploadRecording(formData)
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      recorder.start();
    } catch (err) {
      console.log(err);
      setIsRecord(false);
    }
  }

  function StopRecording() {
    recorder.stop();
    stream.getVideoTracks()[0].stop();
  }

  function handleEndCall() {
    if (localStorage.getItem('user') == 'teacher') {
      setOpen(true);
      return;
    }
    history.push('/');
    onLeave();
  }

  return (
    <div className='control_bar'>
      <div
        className={classNames('video_topleft_icon', {
          video_topleft_icon_hide: !isRecord,
        })}>
        <span>
          <FiberManualRecordIcon />
        </span>
        <span>REC.</span>
      </div>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <img className='video_logo' src={logo} alt='' />
        </Grid>
        <Grid item>
          <div className='video_actions_middle'>
            <span onClick={handleMic}>
              {mic ? (
                <IconButton aria-label='Mute'>
                  <MicIcon className='iconStyleOn' fontSize='large' />
                </IconButton>
              ) : (
                <IconButton aria-label='Unmute'>
                  <MicOffIcon className='iconStyleOff' fontSize='large' />
                </IconButton>
              )}
            </span>
            <span onClick={handleEndCall}>
              <IconButton aria-label='End Call'>
                <CallEndIcon className='callEndIcon' fontSize='large' />
              </IconButton>
            </span>
            <span onClick={handleVideo}>
              {videocam ? (
                <IconButton aria-label='End Call'>
                  <VideocamIcon className='iconStyleOn' fontSize='large' />
                </IconButton>
              ) : (
                <IconButton aria-label='End Call'>
                  <VideocamOffIcon className='iconStyleOff' fontSize='large' />
                </IconButton>
              )}
            </span>
          </div>
        </Grid>
        <Grid item>
          <div className='video_actions_right'>
            <span onClick={onShare} disabled={!isScreenSharingSupported}>
              {isScreenSharingEnabled ? (
                <>
                  <CancelPresentationIcon
                    fontSize='large'
                    className='videoActionOn'
                  />
                  <p>Stop Screen</p>
                </>
              ) : (
                <>
                  <PresentToAllIcon
                    fontSize='large'
                    className='videoActionOn'
                  />
                  <p>Present Screen</p>
                </>
              )}
            </span>
            <span onClick={() => setIsRecord(!isRecord)}>
              {!isRecord ? (
                <div onClick={StartRecording} className='record-on'>
                  <FiberManualRecordIcon
                    fontSize='large'
                    className='videoActionOn'
                  />
                  <p>Start Record</p>
                </div>
              ) : (
                <div onClick={StopRecording} className='record-off'>
                  <HighlightOffIcon
                    fontSize='large'
                    className='videoActionOn'
                  />
                  <p>Stop Record</p>
                </div>
              )}
            </span>
            <span onClick={toggleFullScreen}>
              <FullscreenIcon fontSize='large' className='videoActionOn' />
              <p className='fullscreenText'>Fullscreen</p>
            </span>
          </div>
        </Grid>
      </Grid>
      <EndCallDialogue
        onLeave={onLeave}
        id={eventId}
        open={open}
        setOpen={(v) => setOpen(v)}
      />
    </div>
  );
}

export default ControlBar;
