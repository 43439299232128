import { Box, Typography, useTheme } from "@material-ui/core";
import React from "react";

function EmpyData({ label = "" }) {
  const theme = useTheme();
  let bgColor = theme.palette.secondary.light;

  return (
    <>
      <Box
        ml={2}
        mt={2}
        bgcolor={bgColor}
        p={4}
        borderRadius={20}
        maxWidth={500}
        width="70%"
      >
        <Typography variant="body1" color="textSecondary">
          {label}
        </Typography>
      </Box>
    </>
  );
}

export default EmpyData;
