import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import './myearnings.css'

function Settlements() {
  return (
    <>
      <SettlementCard />
    </>
  )
}

function SettlementCard() {
  let theme = useTheme()
  let bgColor = theme.palette.secondary.light
  return (
    <Box bgcolor={bgColor} borderRadius={10} mt={2} p={2}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="body1">23 Sept, 2020</Typography>
          <Typography
            variant="body2"
            style={{ paddingTop: 5 }}
            color="textSecondary">
            ID : IS89589
          </Typography>
          <Typography
            variant="body2"
            style={{ paddingTop: 5 }}
            color="textSecondary">
            Payment mode : Bank Transfer
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="secondary">One-on-one</Typography>
        </Grid>
      </Grid>
      <Box my={2}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">Amount Settled</Typography>
        <Typography variant="h5" color="primary">&#x20B9; 299/-</Typography>
      </Box>
    </Box>
  )
}

export default Settlements
