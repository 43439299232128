import { IconButton, List, ListItem, Popover } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { logout } from 'assets';
import React from 'react';
import { Link } from "react-router-dom";
import styles from 'Dashboard/Dashboard.module.css';
import studentMenu from 'Dashboard/components/StudentMenu';
import teacherMenu from 'Dashboard/components/TeacherMenu';

function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function logOut() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')
    localStorage.removeItem('is_fully_registered')
    localStorage.removeItem('userId')
    window.location.href = "/"
  }

  let menuItems = localStorage.getItem('user') == "teacher" ? teacherMenu : studentMenu

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-describedby={id}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <AccountCircle
          color="secondary"
          style={{ fontSize: 50 }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav">
          {menuItems.map((item, index) => (
            <Link to={item.path} key={index} style={{ textDecoration: 'none', color: 'initial' }}>
              <ListItem button key={index} style={{ padding: '12px 20px' }}>
                <img src={item.icon} alt="" style={{ marginRight: 16 }} />
                <li className={styles.menu_item}>{item.name}</li>
              </ListItem>
            </Link>
          ))}
          <ListItem onClick={logOut} style={{ padding: '12px 20px' }} button>
            <img width="12.995" height="12.852" src={logout} alt="" style={{ marginRight: 16 }} />
            <li className={styles.menu_item}>Logout</li>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default ProfileMenu
