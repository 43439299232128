import { Box, Button, Typography, useTheme } from '@material-ui/core'
import { bookinsuccess } from 'assets'
import React from 'react'
import { useHistory } from 'react-router-dom'

function BookingSuccess() {
  const theme = useTheme()
  let history = useHistory()
  let bgColor = theme.palette.secondary.light

  return (
    <>
      <Box
        minHeight="100vh"
        bgcolor={bgColor}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Box textAlign="center" width="80%" maxWidth={600}>
          <img className="img_fluid" src={bookinsuccess} alt="" />
          <Typography style={{ paddingTop: 10 }} variant="h4" color="primary">
            Booking Successful
          </Typography>
          <Typography style={{ paddingTop: 10 }} variant="body1">
            Your session is currently active.
            You can go to Chat feature in home page to
            chat with the teacher regarding the session timings
          </Typography>
          <Box mt={2}>
            <Button
              onClick={() => history.push('/chat')}
              variant="contained"
              style={{ minWidth: 200 }}
              color="primary">
              Chat With Teacher
            </Button>
          </Box>
          <Typography
            onClick={() => history.push('/')}
            style={{ paddingTop: 20, cursor: "pointer" }}
            variant="h5"
            color="secondary">
            Go to Dashboard
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default BookingSuccess
