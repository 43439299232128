import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { back, logo, teacherprofile } from 'assets'
import { Link, useHistory } from 'react-router-dom'
import dashboardStyles from 'Dashboard/Dashboard.module.css'
import cx from 'classnames'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

function Profile({ data, ...props }) {
  const history = useHistory()
  const [favorite, setFavorite] = useState(false)

  return (
    <Box className={cx(dashboardStyles.overflow_section)}>
      {props.type === "in_dashboard" ? (
        <Typography variant="h2">My Profile</Typography>
      ) : (
          <>
            <Link to="/welcome">
              <img src={logo} alt="" />
            </Link>
            <Box display="flex" mt={5} gridGap={15} alignItems="center">
              <img
                src={back}
                alt=""
                onClick={() => history.goBack()}
                className="cursor_pointer"
              />
              <Typography variant="h2">Teacher Profile</Typography>
            </Box>
          </>
        )}
      <Box
        display="flex"
        mt={4}
        justifyContent="space-between"
      >
        <Box display="flex" gridGap={16} flexWrap="wrap">
          <img className="img_fluid" src={teacherprofile} alt="" />
          <Box>
            <Typography variant="h4">
              {data?.user?.fullName}
            </Typography>
            <Typography variant="subtitle1" color="primary">
              {data?.subject}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {data?.experience ?? 0} years of Experience
            </Typography>
          </Box>
        </Box>
        {props.type === "in_dashboard" ? null : (
          <Box display="flex" gridGap={5}>
            <Box onClick={() => setFavorite(!favorite)} style={{ cursor: "pointer" }}>
              {favorite ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon color="primary" />}
            </Box>
            <Box>
              <Typography variant="body1">Mark as favourite</Typography>
            </Box>
          </Box>)}
      </Box>
      <Box mt={3}>
        <Typography variant="body2" color="textSecondary">
          Overview
        </Typography>
        <Typography variant="body1">
          {data?.overview}
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="body2" color="textSecondary">Expert in</Typography>
        <Box mt={2} display="flex" gridGap={10} flexWrap="wrap">
          {data?.tags && data?.tags.map(tag => (
            <Box bgcolor="rgba(0,0,0,0.1)" px={2} py={1} borderRadius={16}>
              <Typography variant="body2">{tag}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        mt={4}
        display="flex"
        justifyContent="space-between">
        <Box>
          <Typography variant="body2" color="textSecondary">
            Current Profession
          </Typography>
          <Typography variant="body1">
            {data?.currentProfession}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Profile