import React, { useContext } from "react";
import ReactStopwatch from "react-stopwatch";
import { EndCallContext } from "../index";

const Stopwatch = () => {
	const onLeave = useContext(EndCallContext);
	return (
		<ReactStopwatch
			seconds={0}
			minutes={0}
			hours={0}
			limit='01:00:00'
			onChange={({ hours, minutes, seconds }) => {}}
			onCallback={() => onLeave()}
			render={({ formatted, hours, minutes, seconds }) => {
				return <span>{`${hours}:${minutes}:${seconds}`}</span>;
			}}
		/>
	);
};

export default Stopwatch;
