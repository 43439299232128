import logo from 'assets/images/logo.png'

function getRazorPayOptions({ user, coins, callback }) {
  return (
    {
      key: 'rzp_test_uVdEeJMwoKoSR7',
      amount: coins * 100,
      currency: 'INR',
      name: 'ISHVA',
      description: 'Test Transaction',
      image: logo,
      order_id: '',
      handler: function (response) {
        callback()
      },
      notes: {
        address: 'Ishva',
      },
      theme: {
        color: '#0B5A56',
      },
      prefill: {
        name: user?.fullName,
        email: user?.email,
        contact: user?.mobile
      }
    }
  )
}

export default getRazorPayOptions