import { Box, Typography } from '@material-ui/core';
import { incoming } from 'assets';
import moment from 'moment';
import React from 'react';
import styles from './chat.module.css';

function IncomingChatMessage({ data }) {
  return (
    <>
      <Box className={styles.incoming_msg}>
        <img src={incoming} width='35px' height='35px' alt='' />
        <div>
          {data?.message && <p>{data?.message}</p>}
          {data?.attachment && <a target="blank" href={data?.attachment}>{data?.attachment}</a>} <br />
          <Typography variant="body2" color="textSecondary">
            {moment(data?.time).format('HH:mm A')}
          </Typography>
        </div>
      </Box>
    </>
  );
}

export default IncomingChatMessage
