import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { bag } from 'assets'
import React from 'react'
import './myearnings.css'

function Transactions() {
  return (
    <>
      <TransactionCard />
    </>
  )
}

function TransactionCard() {
  let theme = useTheme()
  let bgColor = theme.palette.secondary.light
  return (
    <Box bgcolor={bgColor} borderRadius={10} mt={2} p={2}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="body1">23 Sept, 2020</Typography>
          <Typography
            variant="body2"
            style={{ paddingTop: 5 }}
            color="textSecondary">
            Classes 07/07 completed
          </Typography>
          <Typography
            variant="body2"
            style={{ paddingTop: 5 }}
            color="textSecondary">
            Bruce Banner | Grade 5
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="secondary">One-on-one</Typography>
        </Grid>
      </Grid>
      <Box my={2}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">Coins received</Typography>
        <Box display="flex" gridGap={7} alignItems="center">
          <img width="14px" src={bag} alt="" />
          <Typography variant="h5" color="primary">299</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Transactions
