import { Box } from '@material-ui/core';
import React from 'react';
import styles from './chat.module.css';

function AttachmentPreview({
  setOpenAttachment,
  handleAttachment,
  previewAttachment,
  attachment,
}) {
  return (
    <>
      <Box className={styles.preview}>
        <header>
          <span onClick={() => setOpenAttachment(false)}>X</span> Preview
        </header>
        <main>
          <div className='text_center'>
            <img src={previewAttachment} alt='' />
            <h4 style={{ fontWeight: 'normal' }}>{attachment.name}</h4>
          </div>
        </main>
        <footer>
          <div className={styles.attachmentSendBtn} onClick={handleAttachment}>
            <svg
              fill='white'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='24'
              height='24'>
              <path d='M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z'></path>
            </svg>
          </div>
        </footer>
      </Box>
    </>
  );
}

export default AttachmentPreview;
