import { Button, Grid, Typography } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import { getUpcomingSession } from 'api/StudentService'
import FullLoader from 'components/FullLoader'
import React, { useEffect, useState } from 'react'
import commonStyles from '../../Dashboard.module.css'
import UpcomingSession from './UpcomingSession'

function Dashboard() {
  let [upcomingSessions, setUpcomingSessions] = useState([])
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    setLoading(true)
    getUpcomingSession().then(res => {
      setUpcomingSessions(res.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }).catch(err => {
      console.log(err)
    })
  }

  if (loading) return <FullLoader />

  return (
    <>
      <section className={commonStyles.overflow_section}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h2">Dashboard</Typography>
          </Grid>
          <Grid item>
            <Button onClick={getData} color='primary' variant='text'>
              <CachedIcon style={{ marginRight: 10 }} />
              Refresh
            </Button>
          </Grid>
        </Grid>
        <UpcomingSession data={upcomingSessions} />
      </section>
    </>
  )
}

export default Dashboard
