import { Box, Button, CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getSubjects } from 'api/StudentService'
import CustomTextField from 'components/CustomTextField'
import useAsync from 'hooks/useAsync'
import React from 'react'
import Error from 'utils/Error'
import CurrentProfession from './CurrentProfession'

function ProfessionalDetails({ nextStep, state, setState }) {
  const [data, loading, error] = useAsync([], getSubjects)

  function handleSubmit(e) {
    let { subject, experience, tags, professions, ...remState } = state
    if (!subject || !experience || !tags.length) {
      Error("All fields are required")
      return
    }
    if (!professions.length) {
      Error("Select atleast one profession")
      return
    }
    e.preventDefault()
    nextStep()
  }

  if (loading) return <CircularProgress />

  return (
    <>
      <p className="text_center para_black step">Step 2/4</p>
      <p className="text_center para_black">Professional Details</p>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Subject"
              required
              InputLabelProps={{ shrink: true }}
              select
              fullWidth
              value={state.subject}
              onChange={(e) => {
                setState({ tags: [] })
                setState({ subject: e.target.value })
              }}
              variant="outlined"
              margin="dense">
              <MenuItem value="">---</MenuItem>
              {data.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              onChange={e => setState({ experience: e.target.value })}
              noMargin
              type="text"
              label="Experience"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={state.subject ? data.filter(sub => sub.name == state.subject)[0].tags : []}
              size="small"
              onChange={(e, value) => setState({ tags: value })}
              getOptionLabel={(option: any) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tags"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={1}>
        <CurrentProfession nextStep={nextStep} state={state} setState={v => setState(v)} />
      </Box>
      <Box>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            onChange={e => setState({ currentProfession: e.target.value })}
            type="text"
            label="currentProfession"
          />
        </Grid>
      </Box>
      <Box mt={3}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          fullWidth
          style={{ borderRadius: 10 }}
        >Proceed</Button>
      </Box>
    </>
  )
}

export default ProfessionalDetails
