import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { db, storageDb } from 'api/firebase';
import ProfileImage from 'components/DefaultImage';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import AttachmentPreview from './AttachmentPreview';
import styles from './chat.module.css';
import IncomingChatMessage from './IncomingMessage';
import InputContainer from './InputContainer';
import OutgoingChatMessage from './OutgoingChatMessage';

function ChatBox({ currentChat, type }) {
  const [messages, setMessages] = useState([]);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [previewAttachment, setPreviewAttachment] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    db.ref(currentChat?.chatRoomId || 'sample').on('value', (snapshot) => {
      let chats = [];
      snapshot.forEach((snap) => {
        chats.push(snap.val());
      });
      setMessages(chats);
    });
  }, [currentChat]);

  function handleAttachment() {
    setOpenAttachment(false);
    var storageRef = storageDb.child(attachment.name);
    storageRef
      .put(attachment)
      .then((res) => {
        res.ref.getDownloadURL().then((url) => {
          console.log(url);
          sendMessageWithAttachment(url);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let sendMessageWithAttachment = (attach) => {
    setMessage('');
    db.ref(currentChat?.chatRoomId).push({
      attachment: attach,
      userId: currentChat?.userId,
      time: moment().format(),
    });
  };

  let sendMessage = (msg) => {
    setMessage('');
    db.ref(currentChat?.chatRoomId).push({
      message: msg,
      userId: currentChat?.userId,
      time: moment().format(),
    });
  };

  function getMessage(item) {
    if (item?.userId == currentChat?.userId) {
      return <OutgoingChatMessage data={item} />;
    }
    return <IncomingChatMessage data={item} />;
  }

  function handleOpenAttachment() {
    setOpenAttachment(true);
  }

  return (
    <>
      <Box height='100%' display='flex' flexDirection='column'>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <ProfileImage
              type='text'
              name={
                type == 'teacher'
                  ? currentChat?.fullName
                  : currentChat?.teacherName
              }
            />
          </Grid>
          <Grid item>
            <Typography variant='h5'>
              {type == 'teacher'
                ? currentChat?.fullName
                : currentChat?.teacherName}
            </Typography>
            <Typography variant='body1' color='textSecondary'>
              {type == 'student' && currentChat?.subjectName
                ? currentChat?.subjectName + ' teacher'
                : null}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Divider />
        </Box>
        <Box className={styles.chatmessages_container}>
          <ScrollToBottom
            className={styles.scrolltobottom}
            scrollViewClassName={styles.scrolltobottominner}>
            {messages.map((item) => {
              return getMessage(item);
            })}
          </ScrollToBottom>
          {openAttachment && (
            <AttachmentPreview
              previewAttachment={previewAttachment}
              attachment={attachment}
              handleAttachment={handleAttachment}
              setOpenAttachment={(v) => setOpenAttachment(v)}
            />
          )}
        </Box>
        {!openAttachment && (
          <Box margin='0px -30px 0px -30px'>
            <InputContainer
              handleOpenAttachment={handleOpenAttachment}
              setAttachment={(v) => setAttachment(v)}
              setPreviewAttachment={(v) => setPreviewAttachment(v)}
              message={message}
              setMessage={(v) => setMessage(v)}
              sendMessage={(v) => sendMessage(v)}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default ChatBox;
