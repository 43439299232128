import { CircularProgress } from '@material-ui/core'
import { getTeacherChats, getTeacherSessionsRequests } from 'api/StudentService'
import FullLoader from 'components/FullLoader'
import Chat from 'Dashboard/components/Chat'
import React, { useEffect, useState } from 'react'

function TeacherChat() {
  let [sessions, setSessions] = useState([])
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getTeacherChats().then(res => {
      setSessions(res.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }).catch(err => {
      setLoading(false)
      console.log(err)
    })
  }, [])

  if (loading) return <FullLoader />

  return (
    <>
      <Chat sessions={sessions || []} type="teacher" />
    </>
  )
}

export default TeacherChat
