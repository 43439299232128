import { Box, CircularProgress } from '@material-ui/core';
import { getProfile } from 'api/StudentService';
import { logo } from 'assets';
import Menu from 'Dashboard/components/Menu';
import Profile from 'Dashboard/components/Profile';
import useFetch from 'hooks/useFetch';
import React, { Context, createContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import menu from '../components/StudentMenu';
import styles from '../Dashboard.module.css';
import Routes from './Routes';

export let UserContext: Context<{}> = createContext({})

function DashboardLayout() {
  let [data, loading, error] = useFetch(getProfile)
  if (loading) return <CircularProgress />

  return (
    <>
      <UserContext.Provider value={data}>
        <div className={styles.root} >
          <nav className={styles.sidenav}>
            <div className={styles.logo}>
              <Link to="/welcome">
                <img src={logo} alt="" />
              </Link>
            </div>
            <Box mt={5} className={styles.profile}>
              <Profile />
            </Box>
            <Box mt={4}>
              <Menu menu={menu} />
            </Box>
          </nav>
          <main className={styles.main_content}>
            <Routes />
          </main>
        </div>
      </UserContext.Provider>
    </>
  )
}

export default withRouter(DashboardLayout) 
