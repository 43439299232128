import { Box, Button, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { teacher } from 'assets'
import React, { useState } from 'react'
import ScheduleSessionDialogue from './ScheduleSessionDialogue'

function NewBookings({ data, getData }) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const bgcolor = theme.palette.secondary.light

  return (
    <Box mt={2}>
      <Box bgcolor={bgcolor} p={2} borderRadius={16}>
        <Box mt={2}>
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <img src={teacher} width="50px" height="50px" alt="" />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{data?.studentName}</Typography>
                    <Typography variant="body1" color="textSecondary">
                      {data?.grade}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {data?.noOfClasses || 0}
                  {" "}Hr Session
                 </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" color="textSecondary">Notes:</Typography>
            <Typography variant="subtitle1">{data?.notes}</Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box mt={3} textAlign="right">
          <Button
            color="primary"
            variant="text"
            onClick={() => setOpen(true)}>
            Schedule first Class
          </Button>
        </Box>
      </Box>
      <ScheduleSessionDialogue
        id={data?.id}
        open={open}
        refresh={getData}
        setOpen={v => setOpen(v)}
      />
    </Box>
  )
}

export default NewBookings
