import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

function Success(text = "") {
  return Swal.fire({
    text: text,
    icon: "success",
    position: "top",
    confirmButtonText: "Ok",
  });
}

export default Success;
