import React from 'react'
import styles from './booksession.module.css'
import Teachers from './Components/Teachers'
import SideNav from './Components/SideNav'

function BookSession() {
  const [filter, setFilter] = React.useState({
    subject: '',
    grade: '',
    tags: []
  })

  return (
    <div className={styles.root} >
      <SideNav filter={filter} setFilter={v => setFilter(v)} />
      <main className={styles.main_content}>
        <Teachers filter={filter} />
      </main>
    </div>

  )
}

export default BookSession
