import { Box, Typography } from '@material-ui/core';
import { outgoing } from 'assets';
import moment from 'moment';
import React from 'react';
import styles from './chat.module.css';

function OutgoingChatMessage({ data }) {
  return (
    <>
      <Box display='flex' justifyContent='flex-end'>
        <Box className={styles.outgoing_msg}>
          <div>
            {data?.message && <p>{data?.message}</p>}
            {data?.attachment &&
              <a target="blank" href={data?.attachment}>{data?.attachment}</a>} <br />
            <Typography variant="body2" color="textSecondary">
              {moment(data?.time).format('HH:mm A')}
            </Typography>
          </div>
          <img src={outgoing} width='35px' height='35px' alt='' />
        </Box>
      </Box>
    </>
  );
}

export default OutgoingChatMessage

