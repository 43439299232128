import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { sessionCompleted } from 'assets';
import React from 'react';
import { useHistory } from 'react-router-dom';

function SessionCompleted() {
  const theme = useTheme();
  let history = useHistory();
  let bgColor = theme.palette.secondary.light;
  const [value, setValue] = React.useState(0);

  return (
    <>
      <Box
        minHeight='100vh'
        display='flex'
        justifyContent='center'
        width='100%'
        bgcolor={bgColor}
        alignItems='center'>
        <Box textAlign='center' width='80%' maxWidth={500}>
          <img className='img_fluid' src={sessionCompleted} alt='' />
          <Typography style={{ paddingTop: 10 }} variant='h4' color='primary'>
            Session Completed
          </Typography>
          <Typography style={{ paddingTop: 10 }} variant='body1'>
            Your session has been completed. Please rate the quality of the
            video call.
          </Typography>
          <Box
            className='session_rating'
            component='fieldset'
            mt={2}
            borderColor='transparent'>
            <Rating
              name='simple-controlled'
              value={value}
              onChange={(event, newValue) => setValue(newValue || 0)}
            />
          </Box>
          <Box mt={2}>
            <Button
              onClick={() => history.push('/my-sessions')}
              variant='contained'
              style={{ minWidth: 200 }}
              color='primary'>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SessionCompleted;
