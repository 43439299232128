import React from 'react';
import { Route, Switch } from "react-router-dom";
import TeacherChat from './Chat';
import Home from './Home';
import MySessions from './MySessions';
import CompletedSessions from './CompletedSessions'
import GroupSessions from 'Dashboard/TeacherDashboard/GroupSessions'
import Profile from './Profile';
import MyEarnings from './MyEarnings';
import UpcomingEvents from './UpcomingEvents';

function Routes() {
  return (
    <Switch>
      <Route path="/one-on-one-sessions" >
        <MySessions />
      </Route>
      <Route path="/completed-sessions" >
        <CompletedSessions />
      </Route>
      <Route path="/group-sessions" >
        <GroupSessions />
      </Route>
      <Route path="/chat" >
        <TeacherChat />
      </Route>
      <Route path="/profile" >
        <Profile />
      </Route>
      <Route path="/my-earnings" >
        <MyEarnings />
      </Route>
      <Route path="/upcoming-events" >
        <UpcomingEvents />
      </Route>
      <Route path="/" >
        <Home />
      </Route>
    </Switch>
  )
}

export default Routes