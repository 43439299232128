import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0B5A56',
      light: '#EBF2F2',
    },
    secondary: {
      main: '#f4bc80',
      dark: '#fadec0',
      light: '#fdf2e6',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        fontSize: 18,
        fontFamily: 'circular-medium',
        textTransform: 'capitalize',
        borderRadius: '10px',
      },
      containedSecondary: {
        color: '#0B5A56',
      },
      outlined: {
        fontSize: 18,
        fontFamily: 'circular-medium',
        textTransform: 'capitalize',
        borderRadius: '10px',
      },
      text: {
        fontSize: 18,
        fontFamily: 'circular-medium',
        textTransform: 'capitalize',
      },
    },
    MuiDialog: {
      root: {
        backdropFilter: 'blur(2px)',
      },
      paper: {
        borderRadius: 20,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '16px',
        fontFamily: 'circular-regular',
      },
      body2: {
        fontSize: '14px',
        fontFamily: 'circular-regular',
        fontWeight: 'lighter',
      },
      h1: {
        fontSize: '34px',
        fontFamily: 'circular-medium',
        fontWeight: 'normal',
      },
      h2: {
        fontSize: '30px',
        fontFamily: 'circular-medium',
        fontWeight: 'normal',
      },
      h3: {
        fontSize: '24px',
        fontFamily: 'circular-medium',
        fontWeight: 'normal',
      },
      h4: {
        fontSize: '20px',
        fontFamily: 'circular-medium',
        fontWeight: 'normal',
      },
      h5: {
        fontSize: '18px',
        fontFamily: 'circular-medium',
        fontWeight: 'normal',
      },

      subtitle1: {
        fontSize: '18px',
        fontFamily: 'circular-regular',
      },
      subtitle2: {
        fontSize: '20px',
        fontFamily: 'circular-regular',
      },
    },
  },
});

export default theme;
