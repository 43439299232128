import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { bookinsuccess, studentEmpty } from 'assets';
import React from 'react';
import { useHistory } from 'react-router-dom';

function StudentEmpytScreen() {
  const theme = useTheme();
  let history = useHistory();
  let bgColor = theme.palette.secondary.light;

  return (
    <>
      <Box
        minHeight='100vh'
        display='flex'
        justifyContent='center'
        width='100%'
        alignItems='center'>
        <Box textAlign='center' width='80%' maxWidth={500}>
          <img className='img_fluid' src={studentEmpty} alt='' />
          <Typography style={{ paddingTop: 10 }} variant='h4' color='primary'>
            Welcome to Ishva
          </Typography>
          <Typography style={{ paddingTop: 10 }} variant='body1'>
            Ishva is digital tuition platform to help your kids connect with
            best teachers in the city.
          </Typography>
          <Box mt={2}>
            <Button
              onClick={() => history.push('/book-session')}
              variant='contained'
              style={{ minWidth: 200 }}
              color='primary'>
              Explore teachers
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default StudentEmpytScreen;
