import { Grid } from '@material-ui/core'
import { getTeacher, getTeacherGroupSessionById } from 'api/StudentService'
import FullLoader from 'components/FullLoader'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Profile from './components/Profile'
import Sessions from './components/sessions'

function TeacherProfile() {
  const match = useRouteMatch()
  let teacherId = match.params.id
  const [data, setData] = useState({})
  const [groupSessions, setGroupSessions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getData()
  }, [])

  async function getData() {
    try {
      const teacher = await getTeacher(teacherId)
      const teacherGroupSessions = await getTeacherGroupSessionById(teacher?.data?.id)
      setData(teacher.data)
      setGroupSessions(teacherGroupSessions.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
    catch (err) {
      console.log(err)
    }
  }

  if (loading) return <FullLoader />

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Profile data={data} />
        </Grid>
        <Grid item xs={6}>
          <Sessions groupSessions={groupSessions} data={data} />
        </Grid>
      </Grid>
    </>
  )
}

export default TeacherProfile
