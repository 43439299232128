export const mapToArray = (map) => Array.from(map.values());

export const toggleFullScreen = () => {
	if (!document.fullscreenElement) {
		document.documentElement.requestFullscreen();
	} else {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	}
};

export function useQuery() {
	return new URLSearchParams(window.location.search);
}
