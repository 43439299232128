import React from 'react'
import { Box, Button, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { teacher } from 'assets'
import commonStyles from '../../Dashboard.module.css'
import SessionDetail from './SessionDetails'
import ProfileImage from 'components/DefaultImage'

let SessionDetails = ({ events, type, ...props }) => {
  const theme = useTheme()
  let bgColor = theme.palette.primary.light

  return (
    <Box bgcolor={bgColor} className={commonStyles.overflow_section}>
      <Box className={type == "teacher" ? "session_details_inner" : ""}>
        <Typography variant="h2">Session Details</Typography>
        <Box py={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <ProfileImage
                type='text'
                name={type == "student" ?
                  props?.currentSession?.teacherName :
                  props?.currentSession?.studentName}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4" color="primary">
                {type == "student" ?
                  props?.currentSession?.teacherName :
                  props?.currentSession?.studentName}
              </Typography>
              <Typography variant="body1" color='textSecondary'>
                {props?.currentSession?.completedClasses ?? "00"}/
                {props?.currentSession?.totalClasses ?? '00'} classes completed
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {Object.keys(events).length ? Object.keys(events).map((key, index) => (
          <>
            <Box alignItems="center" display="flex" mt={3} gridGap={10}>
              <Typography
                color="textSecondary"
                style={{ fontWeight: 500 }}
                variant="body1">
                {key}
              </Typography>
              <Divider style={{ flex: 'auto' }} />
            </Box>
            {events[key].map((details, index) => (
              <SessionDetail
                id={props?.currentSession?.id}
                refresh={props.refresh}
                type={type}
                data={details} />
            ))}
          </>
        )) : (
            <Box py={2}>
              <Typography variant="body1" color='textSecondary'>
                There are no Scheduled evently currently.
              </Typography>
            </Box>
          )}
        {type == "teacher" && (
          <Box mt={2} position="fixed" bottom={20}>
            <Button
              onClick={() => props.setOpen(true)}
              variant="contained"
              color="primary">
              Schedule next event
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SessionDetails