import { Box, MenuItem, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { requestSession } from 'api/StudentService';
import CustomTextField from 'components/CustomTextField';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { grades } from 'utils/data';

function StudentInfoDialogue({
  open,
  setOpen,
  teacherId,
  session,
  selectedSessionPrice
}) {

  const history = useHistory()
  const [state, setState] = useState({
    studentName: '',
    prefferedTime: null,
    grade: '',
    notes: ''
  })

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await requestSession({
        ...state,
        teacherId: teacherId,
        session: selectedSessionPrice,
        noOfClasses: session
      })
      history.push('/booking-success')
    }
    catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box p={5}>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>
            Enter few details to book your session
          </Typography>
          <form action="" onSubmit={handleSubmit}>
            <CustomTextField
              name="studentName"
              onChange={handleChange}
              required
              type="text"
              label="Student Name"
            />
            <Box mt={2}>
              <TextField
                name="grade"
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="dense"
                select
                label="Grade">
                {grades.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <CustomTextField
              name="prefferedTime"
              onChange={handleChange}
              required
              type="datetime-local"
              label="Preferred Time"
            />
            <CustomTextField
              name="notes"
              onChange={handleChange}
              type="text"
              multiline
              rows={5}
              label="Notes"
            />
            <Box mt={3}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  )
}

export default StudentInfoDialogue
