import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage'; // If using Firebase storage

let config = {
  apiKey: 'AIzaSyBgmGDP88xmsuHi3kQxjd4J2zzBlNRrqDA',
  authDomain: 'ishva-19acf.firebaseapp.com',
  databaseURL: 'https://ishva-19acf.firebaseio.com',
  projectId: 'ishva-19acf',
  storageBucket: 'gs://ishva-19acf.appspot.com',
  messagingSenderId: '399938401906',
  appId: '1:399938401906:web:4a17b72e3b8f2894a7f4eb',
};
firebase.initializeApp(config);
export const db = firebase.database();
export const storageDb = firebase.storage().ref();
