import { Box, Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import GroupSessionsList from "./GroupSessions";
import GroupSessionsDetails from "./GroupSessionsDetails";
import CreateGroupSessionDialogue from "./CreateGroupSessionDialogue";
import EmpyData from "components/EmpyData";
import FullLoader from "components/FullLoader";
import { getTeacherGroupSessions } from "api/StudentService";

function GroupSessions() {
  const [open, setOpen] = useState(false);
  const [groupSessions, setGroupSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSession, setCurrentSession] = useState({});

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    getTeacherGroupSessions()
      .then((res) => {
        console.log(res.data);
        setGroupSessions(res.data);
        if (res.data) {
          setCurrentSession(res?.data[0]);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <FullLoader />;

  return (
    <>
      {groupSessions.length ? (
        <>
          <Grid container>
            <Grid item xs={6}>
              <GroupSessionsList
                getData={getData}
                setCurrentSession={setCurrentSession}
                groupSessions={groupSessions}
                setOpen={setOpen}
              />
            </Grid>
            <Grid item xs={6}>
              <GroupSessionsDetails data={currentSession} />
            </Grid>
          </Grid>
        </>
      ) : (
          <Box>
            <EmpyData label="You don't have any group sessions" />
            <Box mt={2} ml={2}>
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                color="primary"
              >
                Create New
            </Button>
            </Box>
          </Box>
        )}
      <CreateGroupSessionDialogue
        refresh={getData}
        open={open}
        setOpen={(v) => setOpen(v)}
      />
    </>
  );
}

export default GroupSessions;
