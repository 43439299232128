import { Grid } from "@material-ui/core";
import {
  getSessionEvents,
  getTeacherCompletedSessions,
} from "api/StudentService";
import EmpyData from "components/EmpyData";
import FullLoader from "components/FullLoader";
import SessionDetails from "Dashboard/components/SessionDetails";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ScheduleSessionDialogue from "../Home/ScheduleSessionDialogue";
import Sessions from "../MySessions/Sessions";

function CompletedSessions() {
  const [teacherEvents, setTeacherEvents] = useState([]);
  let [sessions, setSessions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentSession, setCurrentSession] = useState<any>(null);
  const [open, setOpen] = useState();

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  async function getData() {
    try {
      let teacherSessionRequests = await getTeacherCompletedSessions();
      if (teacherSessionRequests.data.length) {
        let teacherEvents = await getSessionEvents(
          teacherSessionRequests.data[0]?.id
        );
        let data = [...teacherEvents.data].map((item) => ({
          ...item,
          month: moment(item?.time).format("MMMM"),
        }));
        setTeacherEvents(_.groupBy(data, "month"));
      }
      setSessions(teacherSessionRequests.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {
      console.log(err);
    }
  }

  async function refreshEvents(sessionId) {
    getSessionEvents(sessionId)
      .then((res) => {
        let data = [...res.data].map((item) => ({
          ...item,
          month: moment(item?.time).format("MMMM"),
        }));
        setTeacherEvents(_.groupBy(data, "month"));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <FullLoader />;

  return (
    <>
      {sessions.length ? (
        <Grid container>
          <Grid item xs={6}>
            <Sessions
              title="Completed Sessions"
              refresh={refreshEvents}
              sessions={sessions}
              setCurrentSession={(v) => setCurrentSession(v)}
            />
          </Grid>
          <Grid item xs={6}>
            <SessionDetails
              setOpen={(v) => setOpen(v)}
              currentSession={currentSession || sessions[0]}
              type="teacher_completed"
              events={teacherEvents}
            />
          </Grid>
          <ScheduleSessionDialogue
            refresh={refreshEvents}
            id={currentSession?.id || sessions[0]?.id}
            open={open}
            setOpen={(v) => setOpen(v)}
          />
        </Grid>
      ) : (
        <EmpyData label="You didn't complete any sessions yet." />
      )}
    </>
  );
}

export default CompletedSessions;
