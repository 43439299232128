import { Box, Typography, useTheme } from '@material-ui/core'
import EmpyData from 'components/EmpyData'
import moment from 'moment'
import React from 'react'

let Transactions = ({ transactions }) => {
  const theme = useTheme()
  let primayLight = theme.palette.primary.light

  return (
    <Box mt={4}>
      <Typography variant="body1" color="textSecondary">Transactions</Typography>
      {transactions.length ? transactions.map((item, index) => (
        <Box
          key={index}
          p={2}
          mt={2}
          boxShadow={`0px 0px 10px ${primayLight}`}
          display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="body2" color="primary">Status</Typography>
            <Typography variant="h5" style={{ textTransform: "capitalize" }}>
              {item?.status}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="primary">Amount</Typography>
            <Typography variant="h5">
              {item?.coins}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="primary">Payment Data</Typography>
            <Typography variant="h5">
              {moment(item?.createdAt).format('DD/MM/YYYY')}
            </Typography>
          </Box>
        </Box>
      )) : <EmpyData label="You don't have any transactions yet" />
      }
    </Box >
  )
}

export default Transactions