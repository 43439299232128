import React, { useState } from "react";
import Track from "../Track/Track";
import { mapToArray } from "../utils";
import "./participant.css";

const Participant = ({ participant, ...props }) => {
	return (
		<>
			<div onClick={props.handleModal} className='participant'>
				{mapToArray(participant.tracks).map(
					(trackPublication) =>
						trackPublication.track && (
							<Track
								key={trackPublication.track.name}
								track={trackPublication.track}
							/>
						)
				)}
			</div>
		</>
	);
};

export default Participant;
