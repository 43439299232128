import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { uploadFile } from 'api/StudentService';

function ImageUpload({ label = 'Image', name, onChange }) {
  let [imageUrl, setImageUrl] = useState('');

  function handleChange(e) {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      uploadFile(formData)
        .then((res) => {
          onChange(res.data.path);
        })
        .catch((err) => {
          console.log(err);
        });
      let file = e.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (evt) => {
        setImageUrl(evt?.target?.result);
      };
    }
  }

  return (
    <>
      <Box display='flex'>
        <label htmlFor={name}>
          <div className='select_image' title='select your image'>
            {!imageUrl && (
              <span className='image_label select_image_caption'>{label}</span>
            )}
            {imageUrl ? (
              <img src={imageUrl} className='fit_image' />
            ) : (
              <p className='select_image_caption tap_to_upload'>
                Tap to upload your image
              </p>
            )}
            {imageUrl ? (
              <div className='image_overlay'>
                <p>Change Image</p>
              </div>
            ) : null}
          </div>
        </label>
        <input
          type='file'
          onChange={handleChange}
          className='d_none'
          id={name}
        />
      </Box>
    </>
  );
}

export default ImageUpload;
