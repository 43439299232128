import { Box, Button, Typography } from '@material-ui/core';
import { createSlot, getAvailableBalance } from 'api/StudentService';
import EmpyData from 'components/EmpyData';
import React, { useState } from 'react';
import Error from 'utils/Error';
import GroupSessionCard from './GroupSessionCard';
import { useHistory } from 'react-router-dom'
import CoinsShortageDialogue from '../CoinsShortageDialogue';

function GroupSessions({ data }) {
  let history = useHistory()
  const [selectedGroupSession, setSelectedGroupSession] = useState<any>(null)
  const [openCoinsDialogue, setOpenCoinsDialogue] = useState(false)
  const [availableBalance, setAvailableBalance] = useState<any>(null)

  async function handleSubmit() {
    if (!selectedGroupSession) {
      Error("Select a session")
      return
    }
    if (!localStorage.getItem("access_token")) {
      Error("Login to book a session")
      return
    }
    let balance = await getAvailableBalance()
    setAvailableBalance(balance.data?.available_balance)
    checkIfBalanceAvailable(balance.data?.available_balance)
  }

  function checkIfBalanceAvailable(availableBalance) {
    if (+availableBalance < +data[0]?.price) {
      setOpenCoinsDialogue(true)
      return
    }
    createSlot(selectedGroupSession).then(res => {
      history.push('/booking-success')
    }).catch(err => {
      console.log(err)
      Error("No Slots Available")
    })
  }

  return (
    <>
      {data?.length ? (
        <Box mt={5}>
          <Box
            display="flex"
            justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              Group Sessions
            </Typography>
            <Typography variant="body2" color="primary">
              How do group sessions work?
            </Typography>
          </Box>
          {data?.map(item => (
            <GroupSessionCard
              data={item}
              selectedGroupSession={selectedGroupSession}
              setSelectedGroupSession={setSelectedGroupSession} />
          ))}
          <Box mt={4}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Book Session
            </Button>
          </Box>
          {localStorage.getItem('access_token') && (
            <CoinsShortageDialogue
              openCoinsDialogue={openCoinsDialogue}
              setOpenCoinsDialogue={v => setOpenCoinsDialogue(v)}
              availableBalance={availableBalance}
              selectedSessionPrice={data[0]?.price}
            />
          )}
        </Box>
      ) : <EmpyData label="No group sessions are available now." />}
    </>
  )
}

export default GroupSessions
