import { Box, Grid } from '@material-ui/core'
import EmpyData from 'components/EmpyData'
import React, { useEffect, useState } from 'react'
import commonStyles from '../../Dashboard.module.css'
import styles from './chat.module.css'
import ChatBox from './ChatBox'
import ChatContainer from './ChatContainer'

function Chat({ sessions, type }) {
  let [currentChat, setCurrentChat] = useState({})

  useEffect(() => {
    setCurrentChat(sessions[0])
  }, [sessions])

  return (
    <>
      {sessions.length ? (
        <Grid container>
          <Grid item xs={5}>
            <Box className={commonStyles.overflow_section}>
              <ChatContainer setCurrentChat={v => setCurrentChat(v)} type={type} sessions={sessions} />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box className={styles.chatbox}>
              <ChatBox type={type} currentChat={currentChat || sessions[0]} />
            </Box>
          </Grid>
        </Grid>
      ) : (
          <EmpyData label="You dont' have any session request currently" />
        )}
    </>
  )
}

export default Chat
