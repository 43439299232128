import React from 'react'
import { Grid } from '@material-ui/core'
import Footer from './footer'
import styles from '../../Welcome.module.css'
import cx from 'classnames'

function BecomeTeacher() {
  return (
    <>
      <div className={styles.become_techer}>
        <Grid container justify="center">
          <Grid item xs={5}>
            <h1 className={cx(styles.title_white, styles.text_center)}>Become a verified teacher on Ishva</h1>
            <p className={cx(styles.para_white, styles.o7, styles.pt2)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
          </Grid>
          <Grid item xs={10}>
            <div className={styles.form}>
              <form action="">
                <div className={styles.input_container}>
                  <input required type="text" />
                  <span>Full name</span>
                </div>
                <div className={styles.input_container}>
                  <input required type="number" />
                  <span>Contact Number</span>
                </div>
                <div className={styles.input_container}>
                  <input required type="email" />
                  <span>E-mail</span>
                </div>
                <div className={styles.input_container}>
                  <textarea></textarea>
                  <span>Notes</span>
                </div>
                <div className={styles.btn_container}>
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={10}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default BecomeTeacher
