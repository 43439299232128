import React, { useState } from 'react'
import { Box, Button, Grid, MenuItem, TextField } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import Error from 'utils/Error'
import ImageUpload from './ImageUpload'

function AccountInformation({ nextStep, state, setState }) {

  function handleChange({ target: { name, value } }) {
    setState({
      [name]: value
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (!state.idProofImage) {
      Error("Upload Id Proof")
      return
    }
    if (!state.aadhar) {
      Error("Upload Aadhar Card")
      return
    }
    nextStep()
  }

  return (
    <>
      <p className="text_center para_black step">Step 3/4</p>
      <p className="text_center para_black">Account Information</p>
      <form onSubmit={handleSubmit}>
        <Box mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Select Id Proof"
                required
                InputLabelProps={{ shrink: true }}
                select
                name="idProofType"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                margin="dense">
                <MenuItem value="">---</MenuItem>
                {["AadharCard", "Voter Card", "Pan Card"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <ImageUpload
                    name="idProofImage"
                    label="Upload ID Proof"
                    onChange={v => setState({ idProofImage: v })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageUpload
                    name="aadhar"
                    label="Upload aadhar Card"
                    onChange={v => setState({ aadhar: v })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                required
                name="upi"
                type="text"
                label="UPI ID"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="bankName"
                required
                type="text"
                label="Bank Name"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                name="accountNumber"
                onChange={handleChange}
                required
                type="text"
                label="Bank Account Number"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="ifsc"
                required
                type="text"
                onChange={handleChange}
                label="IFSC Code"
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                name="gst"
                required
                type="text"
                label="GST Number"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="tanNumber"
                required
                type="text"
                label="TAN Number"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >Next</Button>
        </Box>
      </form>
    </>
  )
}

export default AccountInformation