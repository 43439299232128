let grades = [
  {
    label: "Class1",
    value: 'class1'
  },
  {
    label: "Class2",
    value: 'class2'
  },
  {
    label: "Class3",
    value: 'class3'
  },
  {
    label: "Class4",
    value: 'class4'
  },
  {
    label: "Class5",
    value: 'class5'
  },
  {
    label: "Class6",
    value: 'class6'
  },
  {
    label: "Class7",
    value: 'class7'
  },
  {
    label: "Class8",
    value: 'class8'
  },
  {
    label: "Class9",
    value: 'class9'
  },
  {
    label: "Class10",
    value: 'class10'
  }
]


export { grades }