import http from "utils/http";

//upload
function uploadFile(file) {
  return http.post("teachers/upload-image", file);
}
function uploadRecording(file) {
  return http.post("/recordings", file);
}

//AUTH
function signup(data) {
  return http.post("/auth/sign-up", data);
}
function login(data) {
  return http.post("/auth/login", data);
}

function getProfile() {
  return http.get("/profile");
}

function getTeacherProfile() {
  return http.get("/teacher-profile");
}

//SUBJECTS
function getSubjects() {
  return http.get("/subjects");
}

//TEACHERS
function getTeachers({ subject = "", grade = "", tags = "" }) {
  return http.get(`/teachers?grade=${grade}&tags=${tags}&subject=${subject}`);
}

function getTeacher(id) {
  return http.get(`/teachers/${id}`);
}

function getTeacherTodayEvents() {
  return http.get("/events/getTeacherTodayEvents");
}

function getTeacherUpcomingEvents() {
  return http.get("/events/getTeacherUpcomingEvents");
}

function updateTeacher(id, data) {
  return http.patch(`/teachers/${id}`, data);
}

function teacherLogin(data) {
  return http.post("/auth/teacher-login", data);
}

function getTeacherSessions(id) {
  return http.get(`/sessions/?teacher_id=${id}`);
}

function getTeacherSessionsRequests() {
  return http.get(`session-requests/teacher`);
}

function getTeacherBookings() {
  return http.get("/session-requests/teacher/bookings");
}

function createGroupSession(data) {
  return http.post("/group-sessions", data);
}

function getTeacherGroupSessions() {
  return http.get("/group-sessions/teacher");
}

function getTeacherGroupSessionById(id) {
  return http.get(`/group-sessions/list/${id}`);
}

function createEvent(data) {
  return http.post("/events", data);
}

function getTeacherChats() {
  return http.get("events/getTeacherChats");
}

function updateEvent(eventId, data) {
  return http.patch(`events/${eventId}`, data);
}

function startVideoCall(data) {
  return http.post(`events/startVideoCall`, data);
}

function joinVideoCall(data) {
  return http.post(`events/joinVideoCall`, data);
}

function getTeacherCompletedSessions() {
  return http.get("/session-requests/getTeacherCompletedSessions");
}

function getTeacherDashboard() {
  return http.get("/statistics/teacherDashboard");
}

//USERS
function getAvailableBalance() {
  return http.get("/transactions/getAvailableBalance");
}

function createTransaction(data) {
  return http.post("/transactions", data);
}

function getTransactions() {
  return http.get("/transactions");
}

function requestSession(data) {
  return http.post("/session-requests", data);
}

function getUserSessions() {
  return http.get("/session-requests");
}

function getUserEvents() {
  return http.get("/events/getUserEvents");
}

function getUpcomingSession() {
  return http.get("events/getUpcomingUserEvents");
}

function getSessionEvents(sessionId) {
  return http.get(`/events/getSessionEvents/${sessionId}`);
}

function getToken(user) {
  return http.get("/users/getVideoCallToken/", {
    params: { user: user },
  });
}

function getUserChats() {
  return http.get("events/getUserChats");
}

function getUserGroupSessions() {
  return http.get("/group-sessions");
}

function createSlot(id) {
  return http.post("/group-sessions/book-slot", { groupSessionId: id });
}

function getUserDashBoard() {
  return http.get("/statistics/userDashbaord");
}

function getUserCompletedSessions() {
  return http.get("/session-requests/getUserCompletedSessions");
}

function getUserUpcomingGroupSessions() {
  return http.get("/group-sessions/getUserEvents");
}

export {
  signup,
  login,
  getSubjects,
  getTeachers,
  getAvailableBalance,
  getTransactions,
  getProfile,
  createTransaction,
  getTeacher,
  getTeacherSessions,
  requestSession,
  getUserSessions,
  teacherLogin,
  updateTeacher,
  getTeacherSessionsRequests,
  getTeacherProfile,
  getUpcomingSession,
  getTeacherBookings,
  createEvent,
  getUserEvents,
  getSessionEvents,
  getTeacherTodayEvents,
  getToken,
  getUserChats,
  getTeacherChats,
  updateEvent,
  getTeacherUpcomingEvents,
  getTeacherGroupSessions,
  createGroupSession,
  getUserGroupSessions,
  createSlot,
  getTeacherGroupSessionById,
  getTeacherCompletedSessions,
  getUserCompletedSessions,
  getUserDashBoard,
  getTeacherDashboard,
  getUserUpcomingGroupSessions,
  uploadFile,
  uploadRecording,
  startVideoCall,
  joinVideoCall,
};
