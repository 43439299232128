import React from 'react'
import { Grid } from '@material-ui/core'
import styles from '../../Welcome.module.css'
import cx from 'classnames'

function About() {
  return (
    <>
      <section className={styles.about}>
        <Grid container justify="center">
          <Grid item xs={5}>
            <h1 className={cx(styles.title_primary, styles.text_center)}>About Ishva</h1>
            <p className={cx(styles.para_black, styles.pt1, styles.o7)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At imperdiet dui accumsan sit. Tincidunt arcu non sodales neque sodales ut etiam sit amet. Id ornare arcu odio ut. Leo a diam sollicitudin tempor id eu nisl nunc mi. Eget nunc scelerisque viverra mauris in. Platea dictumst quisque sagittis purus sit amet volutpat. Ultrices eros in cursus turpis massa tincidunt dui. Cursus risus at ultrices mi tempus. At varius vel pharetra vel turpis nunc eget. Eleifend mi in nulla posuere sollicitudin aliquam. Massa id neque aliquam vestibulum. Velit dignissim sodales ut eu sem integer. Consectetur libero id faucibus nisl tincidunt eget nullam non. Sem fringill a ut morbi tinci dunt. Quisque sa gittis purus sit amet volutpat consequat mauris nunc congue. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla.</p>
          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default About
