import { Box, Button, Radio, Typography, useTheme } from '@material-ui/core'
import { createTransaction } from 'api/StudentService'
import { UserContext } from 'Dashboard/StudentDashboard'
import React, { useContext } from 'react'
import getRazorPayOptions from 'utils/RazorPay'
import commonStyles from '../../../Dashboard.module.css'
import CoinPackage from './CoinPackage'

let BuyCoins = ({ getData }) => {
  const user = useContext(UserContext)
  const [coins, setCoins] = React.useState(100);
  const theme = useTheme()
  let bgColor = theme.palette.primary.light

  const handleChange = (event) => {
    setCoins(event.target.value);
  };

  let completTransaction = () => {
    createTransaction({ type: 'purchase', coins: coins })
      .then(res => {
        getData()
      }).catch(err => {
        console.log(err)
      })
  }

  function buyCoins() {
    let options = getRazorPayOptions({ user: user, coins: coins, callback: completTransaction })
    const razorpay = new (window as any).Razorpay(options);
    razorpay.open();
  }

  return (
    <Box
      bgcolor={bgColor}
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      className={commonStyles.overflow_section}
    >
      <div>
        <Typography variant="h2">Buy Coins</Typography>
        {[100, 200, 500, 1000, 1500, 2000].map((item, index) => (
          <CoinPackage
            key={item}
            index={index}
            value={item}
            selectedValue={coins}
            handleChange={handleChange} />
        ))}
      </div>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={buyCoins}>
          Buy Coins
        </Button>
      </Box>
    </Box>
  )
}



export default BuyCoins