import { Box, Button, Grid } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import { getUpcomingSession } from 'api/StudentService';
import FullLoader from 'components/FullLoader';
import commonStyles from 'Dashboard/Dashboard.module.css';
import React, { useEffect, useState } from 'react';
import UpcomingSession from '../Home/UpcomingSession';

function UpcomingEvents() {
  let [upcomingSessions, setUpcomingSessions] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    getUpcomingSession()
      .then((res) => {
        setUpcomingSessions(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <FullLoader />;

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box className={commonStyles.overflow_section}>
            <Box display='flex' justifyContent='space-between'>
              <h1 className={commonStyles.page_title}>Upcoming Events</h1>
              <Button onClick={getData} color='primary' variant='text'>
                <CachedIcon style={{ marginRight: 10 }} />
                Refresh
              </Button>
            </Box>
            <Box mt={3}>
              <UpcomingSession data={upcomingSessions} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default UpcomingEvents;
