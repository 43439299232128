import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { logo, ivslogo } from 'assets'
import styles from '../../Welcome.module.css'
import cx from 'classnames'

function Footer() {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.lines}>
          <div className={styles.line}></div>
          <div className={styles.line}></div>
          <div className={styles.line}></div>
        </div>
        <Box mt={10} >
          <Grid container justify="space-between" >
            <Grid item xs={5}>
              <Box display="flex" alignItems="center">
                <img src={logo} alt="" />
                <img src={ivslogo} style={{ marginLeft: 30 }} alt="" />
              </Box>
              <p className={cx(styles.para_white, styles.o7, styles.pt2)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. m dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
            </Grid>
            <Grid item xs={5} style={{ alignSelf: 'flex-end' }}>
              <div className={styles.links}>
                <a href="http://www.google.com">Terms of use</a>
                <a href="http://www.google.com">Privacy policy</a>
                <a href="http://www.google.com">Faqs</a>
                <a href="http://www.google.com">About</a>
                <a href="http://www.google.com">Contact</a>
              </div>
              <p className={cx(styles.para_white, styles.text_center, styles.o7, styles.pt2)}
                style={{ fontSize: 12 }}>All rights reserved to Ishva@2020</p>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  )
}

export default Footer
