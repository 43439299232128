import { List, ListItem, useTheme } from '@material-ui/core';
import { logout } from 'assets';
import React from 'react';
import { Link, useHistory } from "react-router-dom";
import styles from '../Dashboard.module.css';


function Menu({ menu }) {
  const history = useHistory()
  const theme = useTheme()

  function logOut() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user')
    localStorage.removeItem('is_fully_registered')
    localStorage.removeItem('userId')
    window.location.href = "/"
  }
  return (
    <>
      <div className={styles.links}>
        <List component="nav">
          {menu.map((item, index) => (
            <Link to={item.path} key={index} style={{ textDecoration: 'none', color: 'initial' }}>
              <ListItem
                selected={history.location.pathname === item.path}
                button
                key={index}
                style={{
                  padding: '18px 0px 18px 30px',
                  borderRight: history.location.pathname === item.path ?
                    `5px solid ${theme.palette.secondary.main}` : ""
                }}>
                <img src={item.icon} alt="" style={{ marginRight: 16 }} />
                <p className={styles.menu_item}>{item.name}</p>
              </ListItem>
            </Link>
          ))}
          <ListItem
            onClick={logOut}
            style={{
              padding: '10px 0px 18px 30px',
              cursor: "pointer"
            }}
          >
            <img width="12.995" height="12.852" src={logout} alt="" style={{ marginRight: 16 }} />
            <p className={styles.menu_item}>Logout</p>
          </ListItem>
        </List>
      </div>
    </>
  )
}

export default Menu


