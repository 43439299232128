import ChatIcon from '@material-ui/icons/Chat';
import PeopleIcon from '@material-ui/icons/People';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import adapter from 'webrtc-adapter';
import ChatBox from './Chat';
import ControlBar from './ControlBar';
import { mapToArray, useQuery } from './utils';
import VideoLoader from './utils/VideoLoader';
import './videocall.css';
import VideoCallContainer from './VideoCallContainer';
import VideoRoom from './VideoRoom/VideoRoom';
import classNames from 'classnames';

export const EndCallContext = React.createContext('');

const App = ({
  videoRoom,
  isVideoSupported,
  isScreenSharingSupported,
  isScreenSharingEnabled,
  onJoin,
  onLeave,
  onShare,
  errorMessage,
}) => {
  let content = null;
  const [mic, setMic] = useState(true);
  const [videocam, setVideocam] = useState(true);
  const [chat, setChat] = useState(false);
  const [loading, setLoading] = useState(false);

  let query = useQuery();
  const roomname = query.get('channel');
  const username = query.get('username');
  const eventId = query.get('eventId');
  const chatRoomId = query.get('chatRoomId');

  useEffect(() => {
    if (window.innerWidth < 768) {
      setChat(true);
    }
    if (adapter.browserDetails.browser === 'firefox') {
      adapter.browserShim.shimGetDisplayMedia(window, 'screen');
    }
    onJoin(roomname, username, setLoading);
  }, []);

  const handleMic = () => {
    setMic((prevState) => !prevState);
    if (mic) {
      videoRoom.localParticipant.audioTracks.forEach((audio) => {
        audio.track.disable();
      });
    } else {
      videoRoom.localParticipant.audioTracks.forEach((audio) => {
        audio.track.enable();
      });
    }
  };

  const handleVideo = () => {
    setVideocam((prevState) => !prevState);
    if (videocam) {
      videoRoom.localParticipant.videoTracks.forEach((video) => {
        video.track.disable();
      });
    } else {
      videoRoom.localParticipant.videoTracks.forEach((item) => {
        item.track.enable();
      });
    }
  };

  const handleChat = () => {
    setChat((prevState) => !prevState);
  };

  if (loading) return <VideoLoader />;

  if (errorMessage) {
    content = <div>{JSON.stringify(errorMessage)}</div>;
  }

  if (!isVideoSupported) {
    content = <div>{JSON.stringify(errorMessage)}</div>;
  } else {
    content = videoRoom && (
      <>
        <div className='video_wrapper'>
          <div
            className={classNames('video_container', {
              video_container_move: chat,
            })}>
            <p
              className={
                isScreenSharingEnabled &&
                videoRoom.localParticipant.identity == 'teacher'
                  ? 'show_screen_text'
                  : 'hide_screen_text'
              }>
              Your Screen is being Shared
            </p>
            <VideoRoom
              videoRoom={videoRoom}
              onLeave={onLeave}
              isScreenSharingEnabled={isScreenSharingEnabled}
            />
            <div
              className={classNames('video_topright_icons', {
                video_topright_icons_attach: !isEmpty(
                  mapToArray(videoRoom.participants)
                ),
              })}>
              <span className='participants_icon'>
                <PeopleIcon />
              </span>
              <span className='chat_icon' onClick={handleChat}>
                <ChatIcon />
              </span>
            </div>
            <ControlBar
              handleMic={handleMic}
              mic={mic}
              onLeave={onLeave}
              eventId={eventId}
              handleVideo={handleVideo}
              onShare={onShare}
              isScreenSharingEnabled={isScreenSharingEnabled}
              isScreenSharingSupported={isScreenSharingSupported}
              videocam={videocam}
            />
          </div>
          <div
            className={classNames('chat_container', {
              chat_container_active: chat,
            })}>
            <ChatBox
              chatRoomId={chatRoomId}
              userId={localStorage.getItem('userId')}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <EndCallContext.Provider value={onLeave}>{content}</EndCallContext.Provider>
  );
};

const render = (containerProps) => <App {...containerProps} />;
export default (props) => <VideoCallContainer render={render} {...props} />;
