import React from 'react'
import { Box, Grid } from '@material-ui/core'
import styles from '../../Welcome.module.css'
import cx from 'classnames'
import { search, verifiedteacher } from 'assets'


function VerifiedTechers() {
  return (
    <>
      <Box py={10} className={styles.verfied_teachers}>
        <Grid container justify="center">
          <Grid item xs={5}>
            <h1 className={cx(styles.title_primary, styles.text_center)}>Explore Verified Techers</h1>
            <p className={cx(styles.para_black, styles.pt1, styles.text_center)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et     dolore magna aliqua. At imperdiet dui accumsan sit. Tincidunt arcu non sodales neque sodales ut etiam sit amet.</p>
            <Box textAlign="center">
              <button className={cx(styles.btn, styles.book_session)}>Book a Session</button>
            </Box>
            <div className={styles.search_container}>
              <div className={styles.search_container_inner}>
                <h4>What do you want to learn?</h4>
                <div className={styles.input_container}>
                  <input type="text" placeholder="Search for a subject" />
                  <img src={search} alt="" />
                  <button className={styles.btn}>Search</button>
                </div>
              </div>
            </div>
            <Box mt={6} textAlign="center">
              <img src={verifiedteacher} className="img_fluid" alt="" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}



export default VerifiedTechers
