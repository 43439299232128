import {
  navicon1,
  navicon3,
  navicon4,
  navicon5,
  navicon6,
  navicon7,
  navicon8,
} from 'assets';

const routes = [
  {
    name: 'My Dashboard',
    path: '/',
    icon: navicon1,
  },
  {
    name: 'Upcoming Events',
    path: '/upcoming-events',
    icon: navicon5,
  },
  {
    name: 'One on one sessions',
    path: '/one-on-one-sessions',
    icon: navicon6,
  },
  {
    name: 'Group Sessions',
    path: '/group-sessions',
    icon: navicon7,
  },
  {
    name: 'Completed Sessions',
    path: '/completed-sessions',
    icon: navicon3,
  },
  {
    name: 'My Earnings',
    path: '/my-earnings',
    icon: navicon8,
  },
  {
    name: 'Chat',
    path: '/chat',
    icon: navicon4,
  },
];

export default routes;
