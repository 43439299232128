import {
  Box,
  Button,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { updateTeacher } from 'api/StudentService';
import React from 'react';
import { useHistory } from 'react-router-dom'

function TermsAndConditions({ nextStep, state, setStep }) {
  const [agree, setAgree] = React.useState(false)
  const history = useHistory()

  const handleChange = (event) => {
    setAgree(event.target.checked);
  };

  let { teacherId, ...data } = state

  function handleSubmit(e) {
    e.preventDefault()
    updateTeacher(teacherId, { ...data, isFullyRegistered: true }).then(res => {
      window.location.href = "/teacher-login"
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <>
      <p className="text_center para_black step">Step 4/4</p>
      <p className="text_center para_black">Terms and Conditions</p>
      <Box mt={2} className="terms">
        <p className="select_profession" style={{ textAlign: 'left' }}>Terms of use</p>
        <p className="terms_content">
          Aliquam faucibus, odio nec commodo aliquam, neque felis placerat dui,
          a porta ante lectu s dapibus est. Aliquam a bibendum mi,
          sed condimentum est. Mauris arcu odio, vestibulu m quis
          dapibus sit amet, finibus id turpis. Aliquam semper fringilla
          semper. Sed nec velit s it amet dolor pulvinar feugiat.
          Suspendisse blandit, nulla sed interdum egestas,
          nibh ex m aximus arcu, non posuere sem nulla in augue.
          Class aptent taciti sociosqu ad litora torque nt per
          conubia nostra, per inceptos himenaeos. Maecenas
          mattis sapien vel sollicitudin bl andit. Donec
          nec porttitor eros. Praesent blandit, erat non
          vehicula rutrum, mauris orci sc elerisque urna, rutrum
          malesuada odio magna at felis. Fusce convallis elit
          in risus tincidunt ullamcorper. Aliquam maximus dolor
          turpis, nec commodo libero mattis ut.
        </p>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box textAlign="center" className="agree_checkbox" mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                required
                size="small"
                checked={agree}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="I have read the terms and conditions"
          />
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >Done</Button>
        </Box>
      </form>

    </>
  )
}

export default TermsAndConditions