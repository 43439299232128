import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import Participant from '../Participant/Participant';
import LocalParticipant from '../Participant/LocalParticipant';
import EventSubscriber from '../EventSubscriber/EventSubscriber';
import { mapToArray } from '../utils';
import TimerIcon from '@material-ui/icons/Timer';
import Timer from '../Timer';
import './videoroom.css';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

const EVENTS = [
  'dominantSpeakerChanged',
  'participantConnected',
  'participantDisconnected',
  'reconnected',
  'reconnecting',
  'trackDimensionsChanged',
  'trackDisabled',
  'trackEnabled',
  'trackPublished',
  'trackStarted',
  'trackSubscribed',
  'trackUnpublished',
  'trackUnsubscribed',
];

class VideoRoom extends PureComponent {
  state = {
    modal: false,
    currentParticipant: 0,
  };

  update = () => this.forceUpdate();

  openModal = (index) => {
    if (this.props.videoRoom?.localParticipant?.identity == 'teacher') {
      this.setState({
        modal: true,
        currentParticipant: index,
      });
    }
  };

  closeModal = () => {
    this.setState({
      modal: false,
      currentParticipant: 0,
    });
  };

  render() {
    const { videoRoom } = this.props;
    const remoteParticipants = mapToArray(videoRoom.participants);
    return (
      <EventSubscriber
        events={EVENTS}
        eventEmitterObject={videoRoom}
        onUpdate={this.update}>
        <div
          className={classNames('inactive_timer_container', {
            active_timer_container: !isEmpty(remoteParticipants),
          })}>
          {!isEmpty(remoteParticipants) && (
            <>
              <TimerIcon size='small' />
              <Timer />
            </>
          )}
        </div>
        <div
          className={classNames('local_video_container', {
            inactive_local_container:
              !isEmpty(remoteParticipants) &&
              videoRoom?.localParticipant.identity !== 'teacher' &&
              remoteParticipants.some((part) => part.identity == 'teacher'),
            local_screen_share_track:
              this.props.isScreenSharingEnabled &&
              videoRoom?.localParticipant.identity == 'teacher',
          })}>
          <LocalParticipant participant={videoRoom.localParticipant} />
        </div>
        {!isEmpty(remoteParticipants) &&
          remoteParticipants.map((participant, index) => {
            if (participant.identity == 'teacher') {
              return (
                <div className='remote_video_container'>
                  <Participant
                    key={participant.sid}
                    participant={participant}
                  />
                </div>
              );
            }
            if (videoRoom?.localParticipant?.identity == 'teacher') {
              return (
                <div
                  className='student_remote_video_container'
                  style={{
                    width: '150px',
                    height: '150px',
                    position: 'absolute',
                    right: 0,
                    top: index * 150,
                  }}>
                  <Participant
                    handleModal={() => this.openModal(index)}
                    key={participant.sid}
                    participant={participant}
                  />
                </div>
              );
            }
          })}
        {this.state.modal && (
          <div className='screenshare_modal'>
            <button onClick={this.closeModal} className='arrow_close'>
              <span>Close</span>
              <CloseIcon fontSize='small' />
            </button>
            <Participant
              participant={remoteParticipants[this.state.currentParticipant]}
            />
          </div>
        )}
      </EventSubscriber>
    );
  }
}

export default VideoRoom;
