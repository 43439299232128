import { Box, Grid } from '@material-ui/core'
import { getTeacherUpcomingEvents } from 'api/StudentService'
import FullLoader from 'components/FullLoader'
import React, { useEffect, useState } from 'react'
import TodayEvents from '../Home/TodayEvents'
import commonStyles from 'Dashboard/Dashboard.module.css'

function UpcomingEvents() {
  const [upcomingEvents, setUpcomingEvents] = useState([])
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    setLoading(true)
    getTeacherUpcomingEvents().then(res => {
      setUpcomingEvents(res.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }).catch(err => {
      console.log(err)
    })
  }

  if (loading) return <FullLoader />;

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box className={commonStyles.overflow_section}>
            <TodayEvents getData={getData} todayEvents={upcomingEvents} label="Upcoming Events" />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default UpcomingEvents
