import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid } from '@material-ui/core'
import { signup } from 'api/StudentService'
import CustomTextField from 'components/CustomTextField'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Error from 'utils/Error'
import '../Login.css'

function Registration({ nextStep }) {
  const [agree, setAgree] = React.useState(false)
  const { control, handleSubmit } = useForm();
  const [btnLoader, setBtnLoader] = useState(false)

  const handleChange = (event) => {
    setAgree(event.target.checked);
  };

  const onSubmit = data => {
    setBtnLoader(true)
    signup(data).then(res => {
      setBtnLoader(false)
      window.location.reload()
    }).catch(err => {
      setBtnLoader(false)
      Error("User with given email already exist")
      console.log(err)
    })
  };

  return (
    <>
      <p className="text_center para_black">Enter details for registration</p>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              as={<CustomTextField label="Full Name" />}
              name="fullName"
              required
              control={control}
              type="text"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={<CustomTextField label="Mobile Number" />}
              name="mobile"
              required
              control={control}
              type="number"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={<CustomTextField label="Email" />}
              name="email"
              required
              control={control}
              type="email"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={<CustomTextField label="password" />}
              name="password"
              required
              control={control}
              type="text"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={<CustomTextField label="Country" />}
              name="country"
              required
              control={control}
              type="text"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={<CustomTextField label="City" />}
              name="city"
              required
              control={control}
              type="text"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={<CustomTextField label="State" />}
              name="state"
              required
              control={control}
              type="text"
            />
          </Grid>
        </Grid>
        <Box textAlign="center" className="agree_checkbox" mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                required
                size="small"
                checked={agree}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="I have read the terms and conditions"
          />
        </Box>
        <Box mt={3}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >{btnLoader ? <CircularProgress color="secondary" /> : "Submit"}</Button>
        </Box>
      </form>
    </>
  )
}

export default Registration