import { Grid } from "@material-ui/core";
import {
  getTeacherBookings, getTeacherUpcomingEvents
} from "api/StudentService";
import FullLoader from "components/FullLoader";
import TeacherEmptyScreen from "pages/TeacherEmptyScreen/TeacherEmptyScreen";
import React, { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import Stats from "./Stats";

function Home() {
  let [bookings, setBookings] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    try {
      let teacherBookings = await getTeacherBookings();
      let upcomingEvents = await getTeacherUpcomingEvents();
      setBookings(teacherBookings.data);
      setUpcomingEvents(upcomingEvents.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {
      console.log(err);
    }
  }

  if (loading) return <FullLoader />;

  return (
    <>
      {bookings.length || upcomingEvents.length ? (
        <Grid container>
          <Grid item xs={6}>
            <Dashboard
              getData={getData}
              bookings={bookings}
              todayEvents={upcomingEvents}
            />
          </Grid>
          <Grid item xs={6}>
            <Stats />
          </Grid>
        </Grid>
      ) : <TeacherEmptyScreen />}
    </>
  );
}

export default Home;
