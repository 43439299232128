import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core'
import theme from 'styles/Theme'
import DashboardLayout from 'Dashboard/StudentDashboard';
import Login from 'pages/Login';
import Welcome from 'pages/Welcome'
import BookSession from 'pages/BookSession';
import TeacherProfile from 'pages/TeacherProfile'
import TeacherLogin from 'pages/TeacherLogin';
import TeacherDashboard from 'Dashboard/TeacherDashboard';
import BookingSuccess from 'pages/BookinSuccess';
import VideoCall from 'pages/videocall';
import SessionCompleted from 'pages/SessionCompleted/SessionCompleted';

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/teacher-login" >
              <TeacherLogin />
            </Route>
            <Route path="/login" >
              <Login />
            </Route>
            <Route path="/welcome">
              <Welcome />
            </Route>
            <Route path="/book-session">
              <BookSession />
            </Route>
            <Route path="/teacher-profile/:id">
              <TeacherProfile />
            </Route>
            <Route path="/booking-success">
              <BookingSuccess />
            </Route>
            <Route path="/video-call">
              <VideoCall />
            </Route>
            <Route path="/session-complete">
              <SessionCompleted />
            </Route>
            <Route path="/teacher-dashboard">
              <TeacherDashboard />
            </Route>
            <Route path="/session-completed">
              <SessionCompleted />
            </Route>
            <Route path="/">
              {renderDashBoard}
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </>
  );
}

function renderDashBoard() {
  if (localStorage.getItem('access_token') && localStorage.getItem('user') == "user") {
    return <DashboardLayout />
  }
  if (
    localStorage.getItem('access_token') &&
    localStorage.getItem('user') == "teacher" &&
    localStorage.getItem('is_fully_registered')
  ) {
    return <TeacherDashboard />
  }
  return <Redirect to="/welcome" />
}

export default App;
