import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

function Error(text = "") {
  return Swal.fire({
    text: text,
    icon: 'error',
    position: 'top',
    confirmButtonText: 'Try Again'
  })
}

export default Error