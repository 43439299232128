import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { mockup } from 'assets'
import styles from '../../Welcome.module.css'
import cx from 'classnames'
import { appstore, playstore } from 'assets'


function Download() {
  return (
    <>
      <div className={styles.download}>
        <Grid container justify="center">
          <Grid item xs={4}>
            <h1 className={cx(styles.title_primary, styles.text_center)}>Download Mobile App</h1>
            <p className={cx(styles.para_black, styles.pt2)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
            <Box display="flex" mt={3} gridGap={10} justifyContent="center">
              <img src={playstore} className="cursor_pointer" alt="" />
              <img src={appstore} className="cursor_pointer" alt="" />
            </Box>
            <img src={mockup} className="img_fluid" alt="" />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Download
