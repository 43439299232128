import { Box, Button, Divider, Typography } from "@material-ui/core";
import React from "react";
import sessionStyles from "../MySessions/mysessions.module.css";
import moment from "moment";
import CachedIcon from "@material-ui/icons/Cached";

function GroupSessionsList({
  groupSessions,
  setOpen,
  setCurrentSession,
  getData,
}) {
  return (
    <Box p={4}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2">Group Sessions</Typography>
        <Button onClick={getData} color="primary" variant="text">
          <CachedIcon style={{ marginRight: 10 }} /> Refresh
        </Button>
      </Box>
      {groupSessions.map((item) => (
        <GroupSessionCard
          setCurrentSession={(v) => setCurrentSession(v)}
          data={item}
        />
      ))}
      <Box mt={2}>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          color="primary"
        >
          Create New
        </Button>
      </Box>
    </Box>
  );
}

const GroupSessionCard = ({ data, setCurrentSession }) => (
  <Box
    mt={2}
    onClick={(v) => setCurrentSession(data)}
    className={sessionStyles.session_card}
  >
    <Box display="flex" justifyContent="space-between">
      <Typography variant="subtitle2">
        {moment(data?.events[0]?.time).format("HH:mm A, DD MMM, YYYY")}
      </Typography>
      <Typography variant="subtitle1" color="primary">
        Rs {data?.session}
      </Typography>
    </Box>
    <Box display="flex" gridGap={2}>
      <Typography variant="body1">Slots filled :</Typography>
      <Typography variant="body1" color="primary">
        {data?.users?.length}/{data?.noOfSlots || 0}
      </Typography>
    </Box>
    <Box my={2}>
      <Divider />
    </Box>
    <Box>
      <Typography variant="body2" color="textSecondary">
        Topics
      </Typography>
      <Typography variant="body1">{data?.events[0]?.topics}</Typography>
    </Box>
  </Box>
);

export default GroupSessionsList;
