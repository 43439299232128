import React from 'react';
import { Route, Switch } from "react-router-dom";
import StudentChat from './Chat/index.js';
import CompletedSessions from './CompletedSessions';
import GroupSessions from './GroupSessions';
import Home from './Home';
import IshvaCoins from './IshvaCoins';
import Mysessions from './Mysessions';
import UpcomingEvents from './UpcomingEvents'

function Routes() {
  return (
    <Switch>
      <Route path="/ishva-coins" >
        <IshvaCoins />
      </Route>
      <Route path="/my-sessions" >
        <Mysessions />
      </Route>
      <Route path="/group-sessions" >
        <GroupSessions />
      </Route>
      <Route path="/completed-sessions" >
        <CompletedSessions />
      </Route>
      <Route path="/chat" >
        <StudentChat />
      </Route>
      <Route path="/upcoming-events">
        <UpcomingEvents />
      </Route>
      <Route path="/" >
        <Home />
      </Route>
    </Switch>
  )
}

export default Routes