import { logo } from 'assets'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AccountInformation from './components/AccountInformations'
import Login from './components/Login'
import PersonalDetails from './components/PersonalDetails'
import ProfessionalDetails from './components/ProfessionalDetails'
import TermsAndConditions from './components/Terms'
import './teacherlogin.css'

function TeacherLogin() {
  const [step, setStep] = useState(1)
  const [state, setState] = useState<any>({
    image: "",
    overview: "",
    qualification: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    subject: "",
    experience: "",
    tags: [],
    teacherType: "teacher",
    professions: [],
    proofType: "",
    idProofImage: "",
    aadhar: "",
    upi: "",
    bankName: "",
    accountNumber: "",
    ifsc: "",
    gst: "",
    tanNumber: "",
    teacherId: "",
    currentProfession: ""
  })

  function renderLoginSteps() {
    switch (step) {
      case 1:
        return <Login nextStep={nextStep} setState={v => setState({ ...state, ...v })} />
      case 2:
        return (
          <PersonalDetails
            state={state}
            setState={v => setState({ ...state, ...v })}
            nextStep={nextStep}
          />
        )
      case 3:
        return (
          <ProfessionalDetails
            state={state}
            setState={v => setState({ ...state, ...v })}
            nextStep={nextStep}
          />
        )
      case 4:
        return (
          <AccountInformation
            state={state}
            setState={(v) => setState({ ...state, ...v })}
            nextStep={nextStep}
          />
        )
      case 5:
        return (
          <TermsAndConditions setStep={setStep} state={state} nextStep={nextStep} />
        )
      default:
        return null
    }
  }

  function nextStep() {
    setStep((step) => step + 1)
  }

  return (
    <>
      <main className="teacher_login">
        <header>
          <Link to="/welcome">
            <img src={logo} className="img_fluid" alt="" />
          </Link>
          <Link to="/login">
            <button className="teacher_login_btn">Student Login</button>
          </Link>
        </header>
        <div className={`teacher_login_form ${step > 1 ? "teacher_register_form" : ""}`}>
          {renderLoginSteps()}
        </div>
      </main>
    </>
  )
}

export default TeacherLogin