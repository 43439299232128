import { Box, Grid } from '@material-ui/core';
import { getAvailableBalance, getTransactions } from 'api/StudentService';
import FullLoader from 'components/FullLoader';
import React, { useEffect } from 'react';
import commonStyles from '../../Dashboard.module.css';
import AvailableCoins from './components/AvailableCoins';
import BuyCoins from './components/BuyCoins';
import Transactions from './components/Transactions';

function IshvaCoins() {
  let [coins, setCoins] = React.useState<any>(0)
  let [transactions, setTransactions] = React.useState<any>([])
  let [loading, setLoading] = React.useState(false)

  useEffect(() => {
    getData()
  }, [])

  let getData = async () => {
    setLoading(true)
    try {
      let availableBalance = await getAvailableBalance()
      let userTransactions = await getTransactions()
      setCoins(availableBalance.data.available_balance)
      setTransactions(userTransactions.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
    catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box className={commonStyles.overflow_section}>
            {loading ?
              <FullLoader /> :
              <>
                <AvailableCoins coins={coins} />
                <Transactions transactions={transactions} />
              </>
            }
          </Box>
        </Grid>
        <Grid item xs={6}>
          <BuyCoins getData={getData} />
        </Grid>
      </Grid>
    </>
  )
}

export default IshvaCoins
