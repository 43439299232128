import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { bookinsuccess, studentEmpty, teachersFiltersEmpty } from 'assets';
import React from 'react';
import { useHistory } from 'react-router-dom';

function TeachersFilterEmptyScreen() {
  const theme = useTheme();
  let history = useHistory();
  let bgColor = theme.palette.secondary.light;

  return (
    <>
      <Box
        minHeight='100vh'
        display='flex'
        justifyContent='center'
        width='100%'
        alignItems='center'>
        <Box textAlign='center' width='80%' maxWidth={500}>
          <img className='img_fluid' src={teachersFiltersEmpty} alt='' />
          <Typography style={{ paddingTop: 10 }} variant='h4' color='primary'>
            No teachers found
          </Typography>
          <Typography style={{ paddingTop: 10 }} variant='body1'>
            There are no teachers to show. Try changing filters to find your
            preferred teachers
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default TeachersFilterEmptyScreen;
