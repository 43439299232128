import React from 'react'
import Banner from './components/Banner'
import Main from './components/Main'
import styles from './Welcome.module.css'

function Index() {
  return (
    <>
      <main className={styles.main_bg}>
        <Banner />
        <Main />
      </main>
    </>
  )
}

export default Index
