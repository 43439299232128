import { Box, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import './myearnings.css'
import Settlements from './Settlements'
import Transactions from './Transactions'

function Earnings() {
  const [tabs, setTabs] = useState({
    transactions: true,
    settlements: false
  })

  function handleTabs(v) {
    console.log(v)
    let tempTabs = { ...tabs }
    Object.keys(tempTabs).forEach((key) => {
      tempTabs[key] = false
    })
    tempTabs[v] = true
    setTabs(tempTabs)
  }

  return (
    <Box p={4}>
      <Typography variant="h2">My Earnings</Typography>
      <Box mt={3}>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              className={tabs.transactions ? "active_tab tab" : "tab"}
              style={{ cursor: "pointer" }}
              variant="h5"
              onClick={() => handleTabs('transactions')}
              color={tabs.transactions ? "primary" : "textSecondary"}>
              Transactions
          </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{ cursor: "pointer" }}
              className={tabs.settlements ? "active_tab tab" : "tab"}
              onClick={() => handleTabs('settlements')}
              variant="h5"
              color={tabs.settlements ? "primary" : "textSecondary"}>
              Settlements
          </Typography>
          </Grid>
        </Grid>
        <Box mt={6}>
          {tabs.transactions && (
            <Box>
              <Typography variant="body2" color="textSecondary">
                Showing all transactions
            </Typography>
              <Transactions />
            </Box>
          )}
          {tabs.settlements && (
            <Box>
              <Typography variant="body2" color="textSecondary">
                Showing all settlements
            </Typography>
              <Settlements />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Earnings
