import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { startVideoCall } from "api/StudentService";
import { teacher } from "assets";
import ProfileImage from "components/DefaultImage";
import EmpyData from "components/EmpyData";
import moment from "moment";
import React from "react";
import { useHistory, Route } from "react-router-dom";

function TodayEvents({ todayEvents, label = "Upcoming Events", ...props }) {
  return (
    <>
      <Box mt={3}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" color="textSecondary">
            {label}
          </Typography>
          {props.getData && (
            <Button onClick={props.getData} color="primary" variant="text">
              <CachedIcon style={{ marginRight: 10 }} /> Refresh
            </Button>
          )}
        </Box>
        {todayEvents.length ? (
          todayEvents.map((item, index) => (
            <TodayEventCard data={item} key={index} />
          ))
        ) : (
          <EmpyData label="You don't have any upcoming Events" />
        )}
      </Box>
    </>
  );
}

function TodayEventCard({ data }) {
  const history = useHistory();
  const theme = useTheme();
  const bgcolor = theme.palette.secondary.light;

  function handleEvent(data: any) {
    startVideoCall({ eventId: data.id })
      .then((res) => {
        window.open(res.data.videoCallURL);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Box mt={2}>
      <Box bgcolor={bgcolor} p={2} borderRadius={16}>
        <Typography variant="subtitle1">
          {moment(data?.time).format("hh:mm a, DD MMMM, YYYY")}
        </Typography>
        <Box mt={2}>
          <Box mt={2}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <ProfileImage type="text" name={data?.studentName} />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      {data?.studentName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Class {data?.completedClasses ?? "00"}/
                      {data?.totalClasses ?? "00"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {moment(data?.time).isBefore(moment().format()) ? (
                  <Button
                    onClick={() => handleEvent(data)}
                    color="primary"
                    variant="text"
                  >
                    Start Call
                  </Button>
                ) : (
                  <Button
                    disabled
                    onClick={() => handleEvent(data)}
                    color="primary"
                    variant="text"
                  >
                    Start Call
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            Topics
          </Typography>
          <Typography variant="body1">{data?.topics}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default TodayEvents;
