import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import { teacher } from "assets";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import EmpyData from "components/EmpyData";
import ProfileImage from "components/DefaultImage";
import { UserContext } from "Dashboard/StudentDashboard";
import { joinVideoCall } from "api/StudentService";

function UpcomingSession(props) {
  let { data } = props;
  return (
    <Box mt={4}>
      <Typography variant="body1" color="textSecondary">
        Upcoming Events
      </Typography>
      {data.length !== 0 ? (
        data.map((item) => <UpcomingSessionCard data={item} />)
      ) : (
        <EmpyData label="You don't have any scheduled events currently" />
      )}
    </Box>
  );
}

function UpcomingSessionCard({ data }) {
  const user: any = useContext(UserContext);
  const history = useHistory();
  const theme = useTheme();
  const bgcolor = theme.palette.secondary.light;

  function handleJoin(data) {
    joinVideoCall({ eventId: data.id })
      .then((res) => {
        window.open(res.data.videoCallURL);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Box bgcolor={bgcolor} mt={2} p={2} borderRadius={16}>
        <Typography variant="h4">
          {moment(data?.time).format("hh:mm a, DD MMMM, YYYY")}
        </Typography>
        <Box mt={2}>
          <Typography variant="h5" color="primary">
            {data?.subjectName} Session
          </Typography>
          <Box mt={2}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <ProfileImage type="text" name={data?.fullName} />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">{data?.fullName}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Class {data?.completedClasses ?? "00"}/
                  {data?.totalClasses ?? "00"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            Topics
          </Typography>
          <Typography variant="body1">{data?.topics}</Typography>
        </Box>
        <Box mt={3}>
          {data?.status == "on-call" ? (
            <Button
              onClick={() => handleJoin(data)}
              variant="contained"
              color="primary"
            >
              Join
            </Button>
          ) : (
            <Button disabled color="primary" variant="contained">
              Join
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

export default UpcomingSession;
