import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { getSubjects } from 'api/StudentService'
import { logo } from 'assets'
import cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { grades } from 'utils/data'
import styles from '../booksession.module.css'
import Filter from './Filter'

function SideNav({ filter, setFilter }) {
  let [subjects, setSubjects] = React.useState<any>([])
  let [loading, setLoading] = React.useState(false)

  function handleTags(s) {
    let tags = [...filter.tags]
    let filteredTags = tags.filter(t => t !== s)
    if (tags.indexOf(s) > -1) setFilter({ ...filter, tags: filteredTags })
    else setFilter({ ...filter, tags: [...filter.tags, s] })
  }

  React.useEffect(() => {
    setLoading(true)
    getSubjects().then(res => {
      setSubjects(res.data)
      setTimeout(() => {
        setLoading(false)
      }, 800);
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }, [])

  function onClear() {
    setFilter({
      subject: '',
      grade: '',
      tags: []
    })
  }

  return (
    <nav className={styles.sidenav}>
      <Box pl={3} pt={2}>
        <Link to="/welcome">
          <img src={logo} alt="" />
        </Link>
      </Box>
      {loading ? <Box textAlign="center"> <CircularProgress /></Box> : (
        <>
          <Box mt={4} px={5} className={cx(styles.filters, "custom_label")}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h3">Filters</Typography>
              <Button color="primary" onClick={onClear}>Clear</Button>
            </Box>
            <Filter
              name="subject"
              onChange={v => setFilter({ ...filter, subject: v })}
              title="Subjects"
              value={filter.subject}
              data={subjects.map(sub => ({ label: sub?.name, value: sub?.name }))}
            />
            <Box mt={4} >
              {filter.subject && (
                <>
                  <Typography variant="body1" color="textSecondary">Tags</Typography>
                  <Box className={styles.tags}>
                    {subjects.filter(sub => sub.name == filter.subject)[0]?.tags.map(s => (
                      <div
                        className={filter.tags.some(t => t == s) && styles.active_tag}
                        onClick={() => handleTags(s)}>
                        <Typography variant="body2">{s}</Typography>
                      </div>
                    ))}
                  </Box>
                </>
              )}
            </Box>
            <Filter
              name="grade"
              onChange={v => setFilter({ ...filter, grade: v })}
              title="Grades"
              value={filter.grade}
              data={grades}
            />
          </Box>
        </>
      )}
    </nav>
  )
}

export default SideNav



