import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { teacher } from "assets";
import EmpyData from "components/EmpyData";
import { UserContext } from "Dashboard/StudentDashboard";
import ProfileImage from "components/DefaultImage";

function UpcomingGroupSessions({ data }) {
  return (
    <>
      <Box>
        {data.length ? (
          data.map((item) => <UpcomingGroupSessionCard data={item} />)
        ) : (
            <EmpyData label="You dont' have any upcoming group sessions" />
          )}
      </Box>
    </>
  );
}

function UpcomingGroupSessionCard({ data }) {
  const history = useHistory();
  const theme = useTheme();
  const bgcolor = theme.palette.secondary.light;
  const user: any = useContext(UserContext);

  function handleJoin(data) {
    history.push(
      `/video-call?channel=${data?.channel}&username=${user?.fullName}&eventId=${data?.id}&chatRoomId=${data?.chatRoomId}`
    );
  }

  return (
    <Box mt={2}>
      <Box bgcolor={bgcolor} p={2} borderRadius={16}>
        <Typography variant="subtitle1">
          {moment(data?.time).format("hh:mm a, DD MMMM, YYYY")}
        </Typography>
        <Box mt={2}>
          <Box mt={2}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <ProfileImage type="text" name={data?.teacherName} />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      {data?.teacherName}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {data?.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            Topics
          </Typography>
          <Typography variant="body1">{data?.topics}</Typography>
        </Box>
        <Box mt={3} textAlign="right">
          {data?.status == "on-call" ? (
            <Button
              onClick={() => handleJoin(data)}
              variant="contained"
              color="primary"
            >
              Join
            </Button>
          ) : (
              <Button disabled color="primary" variant="contained">
                Join
              </Button>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export default UpcomingGroupSessions;
