import React from 'react'
import Header from './Header'
import Content from './Content'
import styles from '../../Welcome.module.css'

function Banner() {
  return (
    <>
      <section className={styles.banner}>
        <Header />
        <Content />
      </section>
    </>
  )
}

export default Banner
