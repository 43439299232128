import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import moment from 'moment'
import React, { useState } from 'react'

function CurrentProfession({ nextStep, state, setState }) {
  const [formData, setFormdata] = useState<any>({
    schoolName: '',
    fromDate: null,
    toDate: null
  })

  function handleChange(e) {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const addProfession = (e) => {
    e.preventDefault()
    setState({
      professions: [
        ...state.professions,
        formData
      ]
    })
  }
  return (
    <>
      <p className="select_profession">Select Profession</p>
      <Box>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={state.teacherType}
            onChange={e => setState({ teacherType: e.target.value })}
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              value="teacher"
              control={<Radio color="primary" />}
              label="Teacher"
            />
            <FormControlLabel value="other" control={<Radio color="primary" />} label="Private Teacher" />
          </RadioGroup>
        </FormControl>
      </Box>
      <form onSubmit={addProfession}>
        <Box mt={2}>
          <CustomTextField
            name="schoolName"
            onChange={handleChange}
            required
            type="text"
            label="School Name"
          />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                name="fromDate"
                onChange={handleChange}
                required
                type="date"
                label="From"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                name="toDate"
                onChange={handleChange}
                required
                type="date"
                label="To"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Button
            type="submit"
            variant="outlined"
            style={{ padding: '3px 30px' }}
            color="primary">Add</Button>
        </Box>
      </form>
      {state.professions.length ? (
        <Box mt={2} className="added_schools">
          <p className="text_center added_schools_title">Added Professions</p>
          {state.professions.map((prof, index) => (
            <Box mt={1}>
              <Grid container justify="space-between">
                <Grid item>
                  <p>{index + 1}. {prof.schoolName}</p>
                </Grid>
                <Grid item>
                  <p>{moment(prof.fromDate).format("MMM, YY")} - {moment(prof.toDate).format("MMM, YY")}</p>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      ) : null}
    </>
  )
}

export default CurrentProfession