import {
  Box,
  FormControl,
  FormControlLabel,
  Radio, RadioGroup,
  Typography
} from '@material-ui/core'
import React from 'react'

function Filter({ name, title, data, onChange, value }) {
  return (
    <Box mt={4}>
      <Typography variant="body1" color="textSecondary">{title}</Typography>
      <FormControl component="fieldset" >
        <RadioGroup
          aria-label="gender"
          onChange={(e) => {
            onChange(e.target.value)
          }}
          value={value}
          name={name}>
          {data.map((item, index) => (
            <FormControlLabel
              key={index}
              style={{ fontSize: 10 }}
              value={item.value}
              control={<Radio color="primary" />}
              label={item.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default Filter