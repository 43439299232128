import { Box, Typography, useTheme } from '@material-ui/core'
import { bag } from 'assets'
import React from 'react'

let AvailableCoins = ({ coins }) => {
  const theme = useTheme()
  let bgColor = theme.palette.secondary.light

  return (
    <>
      <Typography variant="h2">Ishva Coins</Typography>
      <Box mt={4}>
        <Typography variant="body1" color="textSecondary">Your Account</Typography>
        <Box mt={2} p={4} bgcolor={bgColor} borderRadius={16}>
          <Typography variant="h5">Available coins in your account</Typography>
          <Box display="flex" gridGap={10} alignItems="center" mt={1}>
            <img src={bag} alt="" />
            <Typography color="primary" variant="h1">{coins ?? 0} Coins</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AvailableCoins