import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { updateEvent } from "api/StudentService";
import React from "react";
import { useHistory } from "react-router-dom";

export default function EndCallDialogue({ open, setOpen, id, onLeave }) {
	const history = useHistory();
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	function endCall() {
		updateEvent(id, { status: "completed" })
			.then((res) => {
				onLeave();
				history.goBack();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function cancelCall() {
		setOpen(false);
	}

	return (
		<div>
			<Dialog
				maxWidth='sm'
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to end this call?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={endCall} color='primary'>
						Ok
					</Button>
					<Button onClick={cancelCall} color='primary' autoFocus>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
