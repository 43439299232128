import { Box, Grid, Typography } from '@material-ui/core'
import { getTeachers } from 'api/StudentService'
import FullLoader from 'components/FullLoader'
import ProfileMenu from 'components/ProfileMenu'
import TeachersFilterEmptyScreen from 'pages/TeachersFilterEmptyScreen'
import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from '../booksession.module.css'
import TeacherCard from './TeacherCard'

function Teachers({ filter }) {
  let [teachers, setTeachers] = React.useState([])
  let [loading, setLoading] = React.useState(false)
  let isLoggedIn = localStorage.getItem('access_token') ? true : false

  React.useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      getTeachers({
        subject: filter.subject,
        grade: filter.grade,
        tags: filter.tags.join(' ')
      }).then(res => {
        setTeachers(res.data)
        setLoading(false)
      }).catch(err => {
        console.log(err)
        setLoading(false)
      })
    }, 1000);
  }, [filter])

  if (loading) return <FullLoader />

  return (
    <>
      {teachers.length ? (
        <Box px={6} py={3} className={styles.teachers}>
          {isLoggedIn &&
            <Box textAlign="right">
              <ProfileMenu />
            </Box>}
          <>
            <Typography variant="h2">Showing all teachers({teachers?.length})</Typography>
            <Box mt={2}>
              <Grid container spacing={3}>
                {teachers.map((item, index) => <TeacherCard key={index} data={item} />)}
              </Grid>
            </Box>
          </>
        </Box>
      ) : <TeachersFilterEmptyScreen />}
    </>
  )
}

export default withRouter(Teachers)


