import { Box, Grid, Typography } from '@material-ui/core'
import { teacherprofile } from 'assets'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../booksession.module.css'

function TeacherCard({ data }) {
  return (
    <>
      <Grid item xs={12}>
        <Box className={styles.teacher_card}>
          <Box display="flex" gridGap={15}>
            <img src={data?.image ? data.image : teacherprofile} alt="" />
            <Box className={styles.profile_details}>
              <Typography variant="h4" color="primary">{data?.user?.fullName}</Typography>
              <Typography style={{ paddingTop: 5 }} variant="h5">{data?.subject}</Typography>
              <Box mt={2}>
                <Typography variant="body1" className={styles.qualification}>
                  {data?.qualification || "NA"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.price}>
            <Typography variant="body1">Price per session</Typography>
            <Typography variant="h3" color="primary">Rs {data?.session_1}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">Overview</Typography>
            <Typography variant="body1" >{data?.overview}</Typography>
            <Box mt={2} display="flex" gridGap={50}>
              <Box>
                <Typography variant="body2" color="textSecondary">Type</Typography>
                <Typography variant="body1">{data?.teacherType || "NA"}</Typography>
              </Box>
              <Box className={styles.line}></Box>
              <Box>
                <Typography variant="body2" color="textSecondary">Experience</Typography>
                <Typography variant="body1">
                  {data?.experience ? data?.experience + " Years" : "NA"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={3}>
            <Box pb={1}>
              <Typography variant="body2" color="textSecondary">Tags</Typography>
            </Box>
            <Grid container>
              <Grid item xs={9}>
                <Box display="flex" gridGap={5} flexWrap="wrap">
                  {data?.tags ? data?.tags.map(tag => (
                    <Box borderRadius={16} bgcolor="rgba(0,0,0,0.1)" px={2} py={1}>
                      <Typography variant="body2">{tag}</Typography>
                    </Box>
                  )) : null}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box textAlign="right">
                  <Link to={`/teacher-profile/${data?.id}`}>
                    <button className={styles.knowmore_btn}>Know More</button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default TeacherCard