import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

function FullLoader() {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <CircularProgress />
    </Box>
  )
}

export default FullLoader
