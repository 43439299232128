import { Box, Typography, useTheme } from '@material-ui/core'
import React from 'react'

function ProfileImage(props) {
  const theme = useTheme()
  const bgcolor = theme.palette.primary.main

  return (
    <>
      {props.type == "image" ? (
        <img
          width={80}
          height={80}
          style={{ borderRadius: 8, objectFit: "cover" }}
          src={props.src} alt="" />
      ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={props.big ? 80 : 50}
            height={props.big ? 80 : 50}
            borderRadius={10}
            px={2}
            py={1}
            bgcolor={props.bgcolor || bgcolor}>
            <Typography variant="h4" style={{ color: "white", textTransform: "uppercase" }}>
              {props?.name?.[0]}
            </Typography>
          </Box>
        )}
    </>
  )
}

export default ProfileImage
