import { Box, Grid } from '@material-ui/core'
import { teacher } from 'assets'
import ProfileImage from 'components/DefaultImage'
import commonStyles from 'Dashboard/Dashboard.module.css'
import moment from 'moment'
import React from 'react'
import styles from './mysessions.module.css'

function Sessions({ sessions, setCurrentSession, refresh, ...props }) {
  return (
    <>
      <Box className={commonStyles.overflow_section}>
        <h1 className={commonStyles.page_title}>{props.title || "My Sessions"}</h1>
        <Box mt={3}>
          {sessions.map((item: any, index) => (
            <Box className={styles.session_card} onClick={() => {
              refresh(item.id)
              setCurrentSession(item)
            }}>
              <Grid container justify="space-between">
                <Grid item>
                  <Grid container spacing={2} >
                    <Grid item>
                      <ProfileImage
                        type='text'
                        big
                        name={item?.studentName}
                      />
                    </Grid>
                    <Grid item>
                      <h4 className={styles.student_name}>
                        {item?.studentName}
                      </h4>
                      <Box mt={1}>
                        <p className={commonStyles.section_title}>
                          {item?.completedClasses ?? "00"}/{item?.totalClasses ?? '00'} classes completed
                    </p>
                        <p className={commonStyles.section_title}>
                          Last event on
                      {item?.lastSession ? moment(item?.lastSession).format(" DD MMM, YYYY") : " _"}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <h6 className={styles.no_of_hours}>{item.noOfClasses || 0} Hr Session</h6>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default Sessions
