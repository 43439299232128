import { Box, Divider, Radio, Typography } from '@material-ui/core';
import React from 'react'
import moment from 'moment'

const GroupSessionCard = ({ data, selectedGroupSession, setSelectedGroupSession }) => {
  const handleChange = (event) => {
    setSelectedGroupSession(+event.target.value);
  };

  return (
    <Box mt={2} bgcolor="white" p={2} borderRadius={16}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="subtitle2">
            {moment(data?.time).format("HH:mm a, DD MMM, YYYY")}
          </Typography>
          <Typography variant="body2" color="primary">
            {+data?.noOfSlots - +data?.filledSlots} slots available of {data?.noOfSlots}
          </Typography>
        </Box>
        <Typography variant="subtitle1">Rs {data?.price}</Typography>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="body2" color="textSecondary">Topics</Typography>
          <Typography variant="body1">{data?.topics}</Typography>
        </Box>
        <Radio
          value={data?.id}
          color="primary"
          about="dfdf"
          name="group_package"
          checked={selectedGroupSession === data?.id}
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}

export default GroupSessionCard