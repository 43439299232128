import { Box, Button, Typography } from "@material-ui/core";
import { updateEvent } from "api/StudentService";
import moment from "moment";
import React, { useState } from "react";
import Success from "utils/success";
import CompleteEventDialogue from "./CompleteConfirmDialogue";
import RescheduleDialogue from "./RescheduleDialogue";

let SessionDetail = ({ type, data, ...props }) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  function handleUpdateEvent() {
    setOpenConfirm(true);
  }

  function completeEvent() {
    setOpenConfirm(false);
    updateEvent(data?.id, { status: "completed" })
      .then((res) => {
        console.log(res.data);
        Success("Event Complted Sucessfully");
        props.refresh(props?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box display="flex" gridGap={10} mt={3}>
      <div>
        <Typography variant="body1" color="textSecondary">
          {moment(data?.time).format("ddd")}
        </Typography>
        <Typography variant="h4" color="primary">
          {moment(data?.time).format("DD")}
        </Typography>
      </div>
      <Box flex="auto" bgcolor="white" borderRadius={10} p={2}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="body2" color="textSecondary">
              Topics
            </Typography>
            <Box>
              <Typography variant="subtitle1">{data?.topics}</Typography>
            </Box>
          </Box>
          <Typography variant="body2" color="textSecondary">
            {moment(data?.time).format("hh:mm a")}
          </Typography>
        </Box>
      </Box>
      {type == "teacher" && data?.status != "completed" ? (
        <Box alignSelf="center">
          <Box>
            <Button
              onClick={() => setOpen(true)}
              size="small"
              variant="contained"
              color="primary"
            >
              Reschedule
            </Button>
          </Box>
          <Box mt={1}>
            <Button
              onClick={handleUpdateEvent}
              size="small"
              variant="contained"
              color="primary"
            >
              Complete
            </Button>
          </Box>
        </Box>
      ) : null}
      <RescheduleDialogue
        open={open}
        refresh={props?.refresh}
        setOpen={setOpen}
        id={data?.id}
      />
      <CompleteEventDialogue
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleSubmit={completeEvent}
      />
    </Box>
  );
};

export default SessionDetail;
