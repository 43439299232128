import { Box, Button, Grid, Typography, useTheme } from "@material-ui/core";
import { getTeacherDashboard } from "api/StudentService";
import { bag } from "assets";
import FullLoader from "components/FullLoader";
import commonStyles from "Dashboard/Dashboard.module.css";
import React, { useEffect, useState } from "react";

function Stats() {
  const theme = useTheme();
  const bgcolor = theme.palette.primary.light;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    getTeacherDashboard()
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <FullLoader />;
  return (
    <>
      <Box bgcolor={bgcolor} className={commonStyles.overflow_section}>
        <Typography variant="h2">Stats</Typography>
        <TotalSessionsAttended data={data} />
        <TotalEarnings data={data} />
      </Box>
    </>
  );
}

const TotalSessionsAttended = ({ data }) => (
  <Box mt={4}>
    <Typography variant="body1" color="textSecondary">
      Total sessions attended
    </Typography>
    <Box mt={2}>
      <Grid container spacing={4}>
        <StatCard number={data?.one_on_one} content="One on one sessions" />
        <StatCard number={data?.group_sessions} content="Group Sessions" />
        <StatCard number={data?.active_sessions} content="Active Sessions" />
      </Grid>
    </Box>
  </Box>
);

const StatCard = ({ number, content }) => (
  <Grid item xs={6}>
    <Box bgcolor="white" borderRadius={16} px={2} py={3} textAlign="center">
      <Typography variant="h2" color="primary">
        {number}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {content}
      </Typography>
    </Box>
  </Grid>
);

const TotalEarnings = ({ data }) => (
  <Box mt={4}>
    <Typography variant="body1" color="textSecondary">
      Total Earnings
    </Typography>
    <Box
      mt={2}
      display="flex"
      justifyContent="space-between"
      bgcolor="white"
      borderRadius={16}
      px={2}
      py={4}
    >
      <Box display="flex" gridGap={10} alignItems="center">
        <img src={bag} alt="" />
        <Typography variant="h2" color="primary">
          {data?.earnings} Coins
        </Typography>
      </Box>
      <Box>
        <Button disabled variant="contained" color="primary">
          Redeem
        </Button>
      </Box>
    </Box>
  </Box>
);

export default Stats;
