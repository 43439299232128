import React from 'react'
import commonStyles from 'Dashboard/Dashboard.module.css'
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core'
import { bag } from 'assets'

function Details() {
  let theme = useTheme()
  let bgColor = theme.palette.primary.light
  return (
    <>
      <Box px={6} pt={4} bgcolor={bgColor} className={commonStyles.overflow_section}>
        <Typography variant="h2">Details</Typography>
        <Box mt={3}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={6}>
              <DetailCard />
            </Grid>
            <Grid item xs={6}>
              <DetailCard />
            </Grid>
            <Grid item xs={12}>
              <DetailCard />
            </Grid>
          </Grid>
        </Box>
        <Box mt={5}>
          <Button
            fullWidth
            color="primary"
            variant="contained">
            Request Settlement
          </Button>
        </Box>
      </Box>
    </>
  )
}

function DetailCard() {
  return (
    <Box bgcolor="white" borderRadius={16} p={3} textAlign="center">
      <Box display="flex" gridGap={5} justifyContent="center">
        <img src={bag} width="20px" alt="" />
        <Typography variant="h2">6790</Typography>
      </Box>
      <Typography variant="body1" color="textSecondary">Total Revenue</Typography>
    </Box>
  )
}

export default Details
