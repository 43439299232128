import { Grid } from "@material-ui/core";
import {
  getSessionEvents,
  getUserGroupSessions,
  getUserUpcomingGroupSessions,
} from "api/StudentService";
import FullLoader from "components/FullLoader";
import React, { useEffect, useState } from "react";
import GroupSessionDetails from "./GroupSessionDetails";
import Sessions from "./Sessions";

function GroupSessions() {
  let [sessionEvents, setSessionEvents] = useState([]);
  let [groupSessions, setGroupSessions] = useState<any>([]);
  let [UpcomingGroupSessions, setUpcomingGroupSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSession, setCurrentSession] = useState<any>(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    try {
      let userGroupSessions = await getUserGroupSessions();
      let userUpcomingGroupSessions = await getUserUpcomingGroupSessions();
      if (userGroupSessions.data.length) {
        let groupSessionEvents = await getSessionEvents(
          userGroupSessions.data[0]?.id
        );
        setSessionEvents(groupSessionEvents.data);
      }
      setUpcomingGroupSessions(userUpcomingGroupSessions.data);
      setGroupSessions(userGroupSessions.data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {
      console.log(err);
    }
  }

  async function refreshEvents(sessionId) {
    getSessionEvents(sessionId)
      .then((res) => {
        setSessionEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <FullLoader />;

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Sessions
            upcomingSessions={UpcomingGroupSessions}
            label="Group Sessions"
            sessions={groupSessions}
            getData={getData}
            refresh={refreshEvents}
            setCurrentSession={setCurrentSession}
          />
        </Grid>
        {groupSessions.length ? (
          <Grid item xs={6}>
            <GroupSessionDetails data={sessionEvents[0]} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default GroupSessions;
