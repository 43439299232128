import { Box, Divider, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import commonStyles from 'Dashboard/Dashboard.module.css';
import moment from 'moment';

function GroupSessionDetails({ data }) {
  const theme = useTheme();
  const bgcolor = theme.palette.primary.light;

  return (
    <Box className={commonStyles.overflow_section} bgcolor={bgcolor}>
      <Typography variant='h2'>Group Session Details</Typography>
      <Box alignItems='center' display='flex' mt={3} gridGap={10}>
        <Typography
          color='textSecondary'
          style={{ fontWeight: 500 }}
          variant='body1'>
          {moment(data?.time).format('MMM')}
        </Typography>
        <Divider style={{ flex: 'auto' }} />
      </Box>
      <Box display='flex' gridGap={10} mt={3}>
        <div>
          <Typography variant='body1' color='textSecondary'>
            {moment(data?.time).format('ddd')}
          </Typography>
          <Typography variant='h4' color='primary'>
            {moment(data?.time).format('DD')}
          </Typography>
        </div>
        <Box flex='auto' bgcolor='white' borderRadius={10} p={2}>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Typography variant='body2' color='textSecondary'>
                Topics
              </Typography>
              <Box>
                <Typography variant='subtitle1'>{data?.topics}</Typography>
              </Box>
            </Box>
            <Typography variant='body2' color='textSecondary'>
              {moment(data?.time).format('hh:mm a')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GroupSessionDetails;
