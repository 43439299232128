import { Box, Button, CircularProgress, Typography, useTheme } from '@material-ui/core'
import { login } from 'api/StudentService'
import CustomTextField from 'components/CustomTextField'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Error from 'utils/Error'
import '../Login.css'

function Login({ nextStep }) {
  const { control, handleSubmit } = useForm();
  const [btnLoader, setBtnLoader] = useState(false)
  const theme = useTheme()
  const bgcolor = theme.palette.secondary.main

  const onSubmit = data => {
    setBtnLoader(true)
    login(data).then(res => {
      localStorage.setItem('access_token', `Bearer ${res.data.access_token}`)
      localStorage.setItem('user', "user")
      localStorage.setItem("userId", res?.data?.userId)
      setBtnLoader(false)
      window.location.href = "/"
    }).catch(err => {
      setBtnLoader(false)
      Error("Entered Details are wrong.")
      console.log(err)
    })
  };
  return (
    <>
      <Typography variant="body1" style={{ textAlign: "center" }}>
        Login to your Ishva account
      </Typography>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={<CustomTextField label="Email" />}
          name="username"
          required
          control={control}
          type="text"
        />
        <Controller
          as={<CustomTextField label="Password" />}
          name="password"
          required
          control={control}
          type="text"
        />
        <Box mt={3}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >{btnLoader ? <CircularProgress color="secondary" /> : "Submit"}</Button>
        </Box>
        <Box
          style={{ cursor: "pointer" }}
          position="absolute"
          bottom={0}
          left={0}
          bgcolor={bgcolor}
          textAlign="center"
          p={1}
          width="100%"
          onClick={nextStep}>
          <Typography variant="h5" color="primary" >Not registered yet? sign up.</Typography>
        </Box>
      </form>
    </>
  )
}

export default Login