import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';

function CoinsShortageDialogue({
  openCoinsDialogue,
  setOpenCoinsDialogue,
  availableBalance,
  selectedSessionPrice
}) {
  let history = useHistory()

  function handleClose() {
    setOpenCoinsDialogue(false)
  }

  return (
    <Dialog
      open={openCoinsDialogue}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={5}>
        <Typography variant="subtitle2" className="text_center">
          Buy {+selectedSessionPrice - +availableBalance} Credits to select this package
          </Typography>
        <Box
          pt={1}
          display="flex"
          gridGap={5}
          alignItems="center"
          justifyContent="center">
          <Typography variant="body2" color="textSecondary">
            Available Coins :
            </Typography>
          <Typography variant="subtitle1" color="primary">
            {availableBalance} Coins
          </Typography>
        </Box>
        <Box
          gridGap={5}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Typography variant="body2" color="textSecondary">
            Coins to buy :
            </Typography>
          <Typography variant="subtitle1" color="primary">
            {+selectedSessionPrice - +availableBalance} Coins
            </Typography>
        </Box>
        <Box textAlign="center" mt={3}>
          <Button
            style={{ minWidth: 200 }}
            variant="contained"
            onClick={() => history.push('/ishva-coins')}
            color="primary">
            Proceed
            </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default CoinsShortageDialogue
