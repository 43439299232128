import { Grid } from '@material-ui/core'
import { getSessionEvents, getUserCompletedSessions, getUserSessions } from 'api/StudentService'
import EmpyData from 'components/EmpyData'
import FullLoader from 'components/FullLoader'
import SessionDetails from 'Dashboard/components/SessionDetails'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Sessions from '../Mysessions/Sessions'

function CompletedSessions() {
  let [userEvents, setUserEvents] = useState([])
  let [sessions, setSessions] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [currentSession, setCurrentSession] = useState<any>(null)

  useEffect(() => {
    setLoading(true)
    getData()
  }, [])

  async function getData() {
    try {
      let useSessionReqeusts = await getUserCompletedSessions()
      if (useSessionReqeusts.data.length) {
        let teacherEvents = await getSessionEvents(useSessionReqeusts.data[0]?.id)
        let data = formatData(teacherEvents.data)
        setUserEvents(_.groupBy(data, "month"))
      }
      setSessions(useSessionReqeusts.data)
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
    catch (err) {
      console.log(err)
    }
  }

  async function refreshEvents(sessionId) {
    getSessionEvents(sessionId).then(res => {
      let data = formatData(res.data)
      setUserEvents(_.groupBy(data, "month"))
    }).catch(err => {
      console.log(err)
    })
  }

  function formatData(data) {
    return [...data].map(item => (
      { ...item, month: moment(item?.time).format("MMMM") }
    ))
  }

  if (loading) return <FullLoader />

  return (
    <>
      {sessions.length ? (
        <Grid container>
          <Grid item xs={6}>
            <Sessions
              refresh={refreshEvents}
              sessions={sessions}
              setCurrentSession={v => setCurrentSession(v)}
            />
          </Grid>
          <Grid item xs={6}>
            <SessionDetails
              currentSession={currentSession || sessions[0]}
              type="student"
              events={userEvents}
            />
          </Grid>
        </Grid>
      ) : <EmpyData label="You didn't complete any session yet." />}
    </>
  )
}

export default CompletedSessions
